import React, { FC, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';
import { t } from 'i18next';

import { Button, IError, Loader } from '@funfarm/kit';

import { postWillNotPlay, postWillPlay } from 'actions/tournaments';

import css from 'components/Tournaments/TournamentsAll/table.module.scss';

type TournamentRowActionProps = {
  p: ICellRendererParams;
};

const TournamentRowActions: FC<TournamentRowActionProps> = ({ p }) => {
  const [submitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <Button
        color="red"
        view="outlined"
        size="small"
        className={classNames(
          css.button,
          submitting && css.disabled,
          css.willNotPlayBtn,
        )}
        disabled={submitting}
        onClick={() => {
          setSubmitting(true);
          postWillNotPlay([p.data])
            .then(() => {
              console.log('before invalidateQueries');
              queryClient.invalidateQueries({ queryKey: ['schedule'] });
              queryClient.invalidateQueries({ queryKey: ['tourneys'] });
            })
            .catch((error: IError) => {
              console.error(error);
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {submitting ? <Loader size="xsmall" /> : t('Delete')}
      </Button>
      <Button
        color="secondary"
        size="small"
        className={classNames(css.button, css.willPlayBtn)}
        disabled={submitting}
        onClick={() => {
          setSubmitting(true);
          postWillPlay([p.data])
            .then(() => {
              console.log('before invalidateQueries');
              queryClient.invalidateQueries({ queryKey: ['schedule'] });
              queryClient.invalidateQueries({ queryKey: ['tourneys'] });
            })
            .catch((error: IError) => {
              console.error(error);
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {submitting ? <Loader size="xsmall" /> : t('Will play')}
      </Button>
    </>
  );
};

export default TournamentRowActions;
