import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import {
  formatMonths,
  formatNumber,
  IconGrowDown,
  IconGrowUp,
  IError,
  Paper,
  roundDecimal,
  Skeleton,
} from '@funfarm/kit';

import i18n, { localeMap } from 'helpers/i18n';
import { IInfoSummary } from 'types';
import { getSummary } from 'actions';

import { Helper } from 'components/Helper';

export const Indicators = () => {
  const { t } = useTranslation();

  const { isLoading, data, isError } = useQuery<IInfoSummary, IError>({
    queryKey: ['info', 'summary'],
    queryFn: getSummary,
  });

  const inProject = useMemo(() => formatMonths(data?.experience || 0), [data]);

  return (
    <>
      <Paper className="indicator">
        <h4 className="header">
          {t('Top 3')}
          <Helper path="top3" />
        </h4>
        {isLoading || isError ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">${formatNumber(data?.best_cash_amount)}</div>
        )}
        {isLoading || isError ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption">
            {data?.best_cash_date &&
              format(new Date(data?.best_cash_date), 'LLLL yyyy', {
                locale: localeMap[i18n.language],
              })}
          </div>
        )}
        {isLoading || isError ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption">
            <span className="accent">${formatNumber(data?.top2_cash)}</span>{' '}
            {data?.top2_cash_date &&
              format(new Date(data?.top2_cash_date), 'LLL yy', {
                locale: localeMap[i18n.language],
              })}
          </div>
        )}
        {isLoading || isError ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption">
            <span className="accent">${formatNumber(data?.top3_cash)}</span>{' '}
            {data?.top3_cash_date &&
              format(new Date(data?.top3_cash_date), 'LLL yy', {
                locale: localeMap[i18n.language],
              })}
          </div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('ABI')}
          {/*<Helper path="abi" />*/}
        </h4>
        {isLoading || isError ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">
            ${data?.abi ? roundDecimal(data?.abi) : '-'}
            {data?.abi_progress ? (
              data?.abi_progress >= 1 ? (
                <IconGrowUp size="xxlarge" className="color-green" />
              ) : (
                <IconGrowDown size="xxlarge" className="color-red" />
              )
            ) : null}
          </div>
        )}
        {isLoading || isError ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption">{`x${data?.abi_progress ? roundDecimal(data?.abi_progress) : '-'} ${t('last', { count: 3 })} 3 ${t('month', { count: 3 })}`}</div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('ROI')}
          {/*<Helper path="roi" />*/}
        </h4>
        {isLoading || isError ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">
            {data?.roi ? roundDecimal(data?.roi) : '-'}%
          </div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('In project')}
          {/*<Helper path="inProject" />*/}
        </h4>
        {isLoading || isError ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">
            {inProject.years > 0 && (
              <>
                {inProject.years}{' '}
                {t('year', { count: inProject.years, context: 'short' })}{' '}
              </>
            )}
            {inProject.months > 0 && (
              <>
                {inProject.months}{' '}
                {t('month', { count: inProject.months, context: 'short' })}{' '}
              </>
            )}
          </div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('Training')}
          {data?.league && data?.league > 1 && (
            <Helper path={`league_${data?.league - 1}`} />
          )}
        </h4>
        {isLoading || isError ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">
            {data?.league} {t('League')}
          </div>
        )}
        {isLoading || isError ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption">
            {t('Trainer')}: {data?.trainer || '-'}
          </div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('Rang')}
          {/*<Helper path="rating" />*/}
        </h4>
        {isLoading || isError ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">
            {data?.rank}
            {data?.rank_progress ? (
              data?.rank_progress > 0 ? (
                <IconGrowUp size="xxlarge" className="color-green" />
              ) : (
                <IconGrowDown size="xxlarge" className="color-red" />
              )
            ) : null}
          </div>
        )}
        {isLoading || isError ? (
          <Skeleton size="xsmall" />
        ) : (
          data?.rank_progress &&
          data?.rank_progress !== 0 && (
            <div className="caption">
              {`${data?.rank_progress && data?.rank_progress >= 0 ? '+' : ''}${data?.rank_progress} ${t('last', { count: 3 })} 3 ${t('month', { count: 3 })}`}
            </div>
          )
        )}
      </Paper>
    </>
  );
};
