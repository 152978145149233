import React, { useContext, useMemo, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  AllCommunityModule,
  ColDef,
  ColumnState,
  ModuleRegistry,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';

import { TourContext } from '@funfarm/lk/src/components/Tournaments/TourProvider';
import usePagination from '@funfarm/lk/src/hooks/usePagination';
import {
  ApiFetchFunctionNested,
  FilterPrototype,
  TableItemPrototype,
} from '@funfarm/lk/src/types/table';

import { RowClassParams } from 'ag-grid-community/dist/types/src/entities/gridOptions';

import { Badge } from '../Badge';
import Pagination from '../Pagination/Pagination';
import { TableContext } from '../Table';

import columnTypes from './dataGrid.columnTypes';
import { darkTheme } from './dataGrid.themes';

import css from './dataGrid.module.scss';

/**
 * Interface for DataGridWithKey props:
 *      ItemType - type of object to be rendered in a row
 */
type DataGridWithKeyProps<
  ItemType extends TableItemPrototype,
  SelectKey extends string,
> = {
  fetchData: ApiFetchFunctionNested<SelectKey, ItemType, FilterPrototype>;
  fetchKey: string[];
  selectKey: keyof ItemType;
  columns: ColDef<ItemType>[];
  getRowClass?: (params: RowClassParams<ItemType>) => string;
  pagination?: boolean;
  defaultFilters?: FilterPrototype;
  headerRow?: Record<string, unknown>;
};

const DataGridWithKey = <
  ItemType extends TableItemPrototype,
  SelectKey extends string,
>(
  props: DataGridWithKeyProps<ItemType, SelectKey>,
) => {
  /********  PROPS & STATE  */
  const {
    fetchData,
    fetchKey,
    selectKey,
    columns,
    getRowClass,
    pagination = true,
    defaultFilters,
  } = props;
  const { page, setPage, pageSize } = usePagination(100);
  const { orderedBy, orderBy } = useContext(TableContext);
  const { filterParams } = useContext(TourContext);
  const tableRef = useRef<AgGridReact<ItemType>>(null);

  /********  DATA  */
  const { data, isLoading } = useQuery({
    queryKey: [...fetchKey, filterParams, page, pageSize, orderedBy],
    queryFn: () =>
      fetchData(
        {
          ...filterParams,
          ...defaultFilters,
        },
        (page - 1) * pageSize,
        pageSize,
        orderedBy,
      ),
    refetchInterval: 5 * 60 * 1000,
  });
  const itemsCount = useMemo(
    () =>
      data
        ? [selectKey]?.length
          ? data[selectKey].length
          : undefined
        : undefined,
    [data, selectKey],
  );

  /********  RENDERING  */
  return (
    <>
      {!!data && !!data[selectKey]?.length && (
        <div className={css.willPlayRow}>
          {t('Will play')}
          <Badge size="small" className={css.tournamentsCount}>
            {itemsCount}
          </Badge>
        </div>
      )}

      <AgGridReact
        ref={tableRef}
        // data
        loading={isLoading}
        rowData={data ? data[selectKey] : []}
        // columns
        columnDefs={columns}
        columnTypes={columnTypes}
        // selection
        getRowId={(params) => String(params.data.id)}
        rowSelection={{ mode: 'multiRow' }}
        // behaviour
        suppressDragLeaveHidesColumns
        suppressRowHoverHighlight={true}
        alwaysShowVerticalScroll={true}
        noRowsOverlayComponent={() => <div style={{ display: 'none' }} />}
        // sorting
        onSortChanged={(params: any) => {
          setTimeout(() => {
            const sortedColumn = params.api
              .getColumnState()
              .find((col: ColumnState) => col.sort);
            if (!sortedColumn) return;

            const { colId: field, sort: direction } = sortedColumn;
            console.log('Updated sorting:', field, direction);
            orderBy!(field, direction);
          }, 0);
        }}
        // appearance
        defaultColDef={{
          flex: 1, // Ensures all columns expand
          minWidth: 100,
          resizable: false,
          suppressMovable: true,
          sortable: true,
          sortingOrder: ['asc', 'desc'],
          comparator: () => 0,
        }}
        suppressMultiSort={true}
        theme={darkTheme}
        getRowClass={getRowClass}
        domLayout="autoHeight"
      />

      {pagination && (
        <Pagination
          page={page}
          setPage={setPage}
          loading={isLoading}
          hasNextPage={
            !isLoading && data?.length ? data?.length > pageSize : false
          }
        />
      )}
    </>
  );
};

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

export default DataGridWithKey;
