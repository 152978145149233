import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonProps,
  Dialog,
  Error,
  IconHelpCircle,
  Loader,
} from '@funfarm/kit';

import i18n from 'helpers/i18n';

import css from './helper.module.scss';

interface IProps {
  path: string;
  header?: string;
  className?: string;
  style?: CSSProperties;
  buttonProps?: ButtonProps;
  xlarge?: boolean;
}

export const Helper = (props: IProps) => {
  const { path, header, className, style, buttonProps, xlarge } = props;
  const { t } = useTranslation();
  const currentLang = i18n.language;

  const [htmlHeader, setHtmlHeader] = useState<string>(header ?? '');
  const [htmlContent, setHtmlContent] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchHtml = () => {
    setOpen(true);
    setLoading(true);

    fetch(`/helpers/${path}_${currentLang}.html`)
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const h1 = doc.querySelector('h1');
        if (h1) {
          setHtmlHeader(h1.textContent as string);
          h1.remove(); // Удаляем <h1> из DOM
        }

        setHtmlContent(doc.body.innerHTML);
      })
      .catch((error) => {
        console.error('Error loading HTML file:', error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {buttonProps ? (
        <Button {...buttonProps} onClick={fetchHtml} />
      ) : (
        <IconHelpCircle
          onClick={fetchHtml}
          size="small"
          className={className}
          style={style}
        />
      )}
      {
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          header={htmlHeader}
          {...(xlarge ? { xlarge: true } : { wide: true })}
        >
          {loading ? (
            <Loader layout style={{ color: '#FFDC5D' }} />
          ) : htmlContent && htmlContent.length ? (
            <div
              className={css.body}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          ) : (
            <Error layout message={t("Can't find reference")} />
          )}
        </Dialog>
      }
    </>
  );
};
