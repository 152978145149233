import React, { FC } from 'react';

import PageHeading from 'components/PageHeading/PageHeading';

const FinanceHeading: FC = () => (
  <PageHeading
    tabs={[
      { title: 'Баланс', to: '/finance-balance' },
      { title: 'Переводы', to: '/finance-requests' },
    ]}
    title="Финансы"
  />
);

export default FinanceHeading;
