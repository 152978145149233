import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import css from './popover.module.scss';

type VerticalPositionType = 'top' | 'bottom';
type HorizontalPositionType = 'left' | 'right' | 'center';

interface PopoverProps extends PropsWithChildren {
  horizontalPosition?: HorizontalPositionType;
  verticalPosition?: VerticalPositionType;
  trigger: React.ReactNode;
  styles: React.CSSProperties;
}

const Popover: React.FC<PopoverProps> = ({
  horizontalPosition = 'left',
  verticalPosition = 'bottom',
  trigger,
  children,
  styles,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const togglePopover = () => setIsOpen((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={css.popoverContainer}>
      <div ref={triggerRef} onClick={togglePopover} className={css.trigger}>
        {trigger}
      </div>
      {/* {isOpen && ( */}
      <div
        ref={popoverRef}
        className={classNames(
          css.popover,
          isOpen ? css.isOpen : '',
          css[horizontalPosition],
          css[verticalPosition],
        )}
        style={styles}
      >
        {children}
      </div>
      {/* )} */}
    </div>
  );
};

export default Popover;
