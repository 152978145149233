import React, { FC, PropsWithChildren } from 'react';

import Metrics, { MetricsProps } from '@funfarm/kit/Metrics';

import css from 'components/Finances/finances.module.scss';
import PageHeading, {
  PageHeadingProps,
} from 'components/PageHeading/PageHeading';

type DrawerLayoutProps = PropsWithChildren & {
  pageHeadingProps?: PageHeadingProps;
  metricsProps?: MetricsProps;
};

const DrawerLayout: FC<DrawerLayoutProps> = ({
  pageHeadingProps,
  metricsProps,
  children,
}) => (
  <>
    {pageHeadingProps && <PageHeading {...pageHeadingProps} />}
    <div className={css.transfersContainer}>
      {metricsProps?.value && (
        <div className={css.transfersTotal}>
          <Metrics {...metricsProps} />
        </div>
      )}
      <div className={css.transfersTable}>{children}</div>
    </div>
  </>
);

export default DrawerLayout;
