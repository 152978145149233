import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import {
  IconChecked,
  IconChevronDown,
  IconChevronUp,
  IconSpinner,
  ISelectProps,
  useOnClickOutside,
} from '@funfarm/kit';
import { ELabelPosition, IEventTarget, IOption } from '@funfarm/kit/types';

import { convertInputValue } from './helpers/convertInputValue';

import { SelectedBadges } from './SelectedBadges';

import css from './select.module.scss';

export interface IMultiSelectProps extends Omit<ISelectProps, 'value'> {
  value?: (string | number)[] | ISelectProps['value'];
  maxBadges?: number;
  checkboxes?: boolean;
}

export const MultiSelect = forwardRef(
  (props: IMultiSelectProps, _ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      label,
      labelPosition = ELabelPosition.top,
      error,
      displayError,
      required,
      disabled,
      readonly,
      placeholder,
      onFocus,
      onBlur,
      onChange,
      defaultLabel,
      className,
      style,
      options,
      loading,
      checkboxes,
      dataTestId,
      addonRight,
      maxBadges,
    } = props;
    // value has to be a string
    const value = props.value ? props.value.toString() : '';

    const [internalValue, setInternalValue] = useState<(string | number)[]>(
      convertInputValue(value),
    );
    const [currentLabel, setLabel] = useState<
      IMultiSelectProps['defaultLabel']
    >(defaultLabel ?? label ?? '');
    const [open, setOpen] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [currentOptions, setOptions] = useState<IOption[]>(options ?? []);

    const labelRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(wrapperRef, () => handleClose());

    useEffect(() => {
      setInternalValue(convertInputValue(value));
      handleSetLabel();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, loading]);

    const setOptionsChecked = useCallback(() => {
      setOptions((options) => {
        return options.map((opt) => {
          return {
            ...opt,
            checked: convertInputValue(valueRef.current?.value).includes(
              opt.value.toString(),
            ),
          };
        });
      });
    }, []);

    useEffect(() => {
      setOptions(options);
      setOptionsChecked();
    }, [options, required, setOptionsChecked]);

    const handleSetLabel = useCallback(() => {
      if (labelPosition === ELabelPosition.inside && !value)
        return setLabel(defaultLabel ?? label);

      setLabel(defaultLabel ?? '');
      setOptionsChecked();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, defaultLabel, setOptionsChecked]);

    const handleSetValue = (option: IOption) => {
      // это нужно чтобы форма получила новое значение
      if (option.disabled) return;

      if (checkboxes && option.checked) return handleDelete(option.value)();

      if (valueRef.current) {
        valueRef.current.value = valueRef.current.value
          .split(',')
          .filter((v) => v && v.toString() !== option.value.toString())
          .concat(option.value.toString())
          .toString();

        const event = new Event('input', { bubbles: true });

        valueRef.current.dispatchEvent(event);
        setInternalValue(convertInputValue(valueRef.current.value));

        setOptionsChecked();
      }
    };

    const handleChange = (
      event: ChangeEvent<HTMLInputElement> | IEventTarget,
    ) => {
      if (onChange) onChange(event as ChangeEvent<HTMLInputElement>);
    };

    const handleOpen = useCallback(() => {
      if (readonly || disabled) return;

      setOpen(true);
    }, [disabled, readonly]);

    const handleClose = useCallback(() => {
      setOpen(false);
    }, []);

    const handleFocus = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (readonly || disabled) return;

        setFocus(true);
        labelRef.current?.focus();

        handleOpen();

        if (onFocus) onFocus(event);
      },
      [disabled, readonly, handleOpen, onFocus],
    );

    const handleBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (!checkboxes) {
          setFocus(false);

          handleClose();
        }

        if (onBlur) onBlur(event);
      },
      [handleClose, onBlur, checkboxes],
    );

    const handleDelete = (optionValue: string | number) => {
      return () => {
        if (valueRef.current) {
          valueRef.current.value = valueRef.current.value
            .split(',')
            .filter((v) => v && v.toString() !== optionValue.toString())
            .toString();

          const event = new Event('input', { bubbles: true });

          valueRef.current.dispatchEvent(event);
          setInternalValue(convertInputValue(valueRef.current.value));

          setOptionsChecked();
        }
      };
    };

    return (
      <div
        className={classNames(
          css.select,
          error && css['error'],
          disabled && css['disabled'],
          readonly && css['readonly'],
          css.labelPosition,
          css[labelPosition],
          className,
        )}
        style={style}
        ref={wrapperRef}
      >
        {label && labelPosition !== ELabelPosition.inside && (
          <label
            htmlFor={name}
            className={classNames(css.label, css[labelPosition])}
          >
            {label}
            {required && <span className={css.mark}>*</span>}
          </label>
        )}
        <div className={css.errorWrapper}>
          <div className={css.addonWrapper}>
            <div
              className={classNames(
                css.wrapper,
                disabled && css.wrapperDisabled,
                readonly && css.wrapperReadonly,
                error && css.wrapperError,
                focus && css.focus,
              )}
            >
              <input
                id={name}
                readOnly
                type="hidden"
                name={name}
                value={value}
                disabled={disabled}
                onInput={(e) =>
                  handleChange(e as ChangeEvent<HTMLInputElement>)
                }
                data-testid={dataTestId}
                ref={valueRef}
              />
              <div className={css.badgesWrapper}>
                <SelectedBadges
                  values={internalValue}
                  maxBadges={maxBadges}
                  disabled={disabled}
                  handleDelete={handleDelete}
                  readonly={readonly}
                  options={currentOptions}
                />
              </div>
              <input
                id={name + '-label'}
                name={name + '-label'}
                disabled={disabled}
                required={required}
                readOnly
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={currentLabel}
                type="text"
                autoComplete="off"
                ref={labelRef}
                className={classNames(
                  labelPosition === ELabelPosition.inside &&
                    !value &&
                    css[labelPosition],
                )}
              />
              {loading ? (
                <IconSpinner className={classNames('spin', css.chevron)} />
              ) : open ? (
                <IconChevronUp
                  className={css.chevron}
                  onClick={() =>
                    handleBlur({} as ChangeEvent<HTMLInputElement>)
                  }
                />
              ) : (
                <IconChevronDown
                  className={css.chevron}
                  onClick={() =>
                    handleFocus({} as ChangeEvent<HTMLInputElement>)
                  }
                />
              )}
              <ul className={classNames(css.options, open && css.open)}>
                {currentOptions.map((option: IOption) => (
                  <li
                    key={option.value}
                    className={classNames(
                      css.option,
                      option.disabled && css.disabled,
                      checkboxes && css.checkboxes,
                      option.checked && css.checked,
                    )}
                    onMouseDown={() => handleSetValue(option)}
                  >
                    {checkboxes && option.checked && (
                      <IconChecked className={css.icon} />
                    )}
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
            {addonRight &&
              React.cloneElement(addonRight, {
                className: classNames(
                  addonRight.props.className,
                  css['addonRight'],
                ),
              })}
          </div>
          {displayError && error && <p className={css.error}>{error}</p>}
        </div>
      </div>
    );
  },
);
