import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import {
  BarElement,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  PointElement,
  Title,
} from 'chart.js';
import { addDays, format } from 'date-fns';

import { Error, IError, Loader, ucFirst } from '@funfarm/kit';

import { defaultChartOptions } from 'helpers';
import i18n, { localeMap } from 'helpers/i18n';
import { EPeriod, IDailyScheduleData } from 'types';
import { getDailySchedule } from 'actions';

ChartJS.register(PointElement, Title, Legend, Filler, BarElement);

interface IProps {
  period: keyof typeof EPeriod;
}

export const DailyChart = (props: IProps) => {
  const { period } = props;
  const { t } = useTranslation();

  const { isLoading, data, isError } = useQuery<IDailyScheduleData, IError>({
    queryKey: ['select', 'daily', period],
    queryFn: () => getDailySchedule(period),
  });

  const daysOfWeek = useMemo(() => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);

    return Array.from({ length: 7 }, (_, index) => {
      const day = addDays(startOfWeek, index);
      return ucFirst(format(day, 'EEE', { locale: localeMap[i18n.language] }));
    });
  }, []);

  const chartOptions: ChartOptions<'bar'> = useMemo(
    () => ({
      ...defaultChartOptions,
      indexAxis: 'y' as const,
      scales: {
        ...defaultChartOptions.scales,
        y: {
          type: 'category',
          grid: {},
          ticks: {},
          labels: data?.days.map((_value, index) => daysOfWeek[index]),
        },
      },
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
    }),
    [data, daysOfWeek],
  );

  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          pointRadius: 0,
          lineTension: 0.4,
          data: data?.days,
          fill: true,
          borderColor: ['#FFD04C'],
          backgroundColor: ['#FFD04C'],
        },
      ],
    };
  }, [data]);

  if (isLoading)
    return <Loader layout size="small" style={{ color: '#FFDC5D' }} />;

  if (isError) return <Error message={t('No data provided')} />;

  return <Bar options={chartOptions} data={chartData} />;
};
