import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { t } from 'i18next';

import {
  Badge,
  Button,
  Dialog,
  IconChecked,
  IconClose,
  Skeleton,
} from '@funfarm/kit';
import DataGrid from '@funfarm/kit/DataGrid/DataGrid';

import { durationInDays, formatPeriod } from 'helpers/formatDates';
import { FinanceCalculation } from 'types/finances';
import { FilterPrototype } from 'types/table';
import { getCalculations } from 'actions/finances';

import DrawerLayout from 'components/Finances/DrawerLayout';
import ClosePackage from 'components/Finances/PlayerPackages/ClosePackage';
import { NavigationContext } from 'components/Layout/NavigationProvider';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

import css from '../finances.module.scss';

interface PlayersPackagesProps {
  packageProfit: number;
}

const PlayersPackages: FC<PlayersPackagesProps> = ({ packageProfit }) => {
  const [completingPackageOpen, setCompletingPackageOpen] = useState(false);
  const [packageJustClosed, setPackageJustClosed] = useState(false);
  const { closeDrawer } = useContext(NavigationContext);
  const { currentPackage, isLoading, isCurrentPackageError } =
    useBalancesAndBrm();

  useEffect(() => {
    if (completingPackageOpen) {
      setInterval(() => setPackageJustClosed(false), 5000);
    }
  }, [completingPackageOpen]);

  const columnDefs = useMemo<ColDef<FinanceCalculation>[]>(
    () => [
      {
        field: 'date_start',
        headerName: 'Период',
        cellRenderer: (p: ValueFormatterParams) =>
          formatPeriod(p.data.date_start, p.data.date_end),
      },
      {
        field: 'date_end',
        headerName: 'Длительность',
        cellRenderer: (p: ValueFormatterParams) => {
          const count = durationInDays(p.data.date_start, p.data.date_end);
          return count + ' ' + t('day', { count });
        },
      },
      { field: 'condition', headerName: 'Условия' },
      { field: 'profit', headerName: 'Результат, $', type: 'amount' },
      { field: 'profit_user', headerName: 'Доля игрока, $', type: 'amount' },
      {
        field: 'package_status',
        headerName: 'Статус пакета',
        type: 'badge',
        refData: {
          open: 'blue',
          close: 'green',
          cancell: 'red',
          final: 'default',
          hold: 'orange',
          translationPrefix: 'package_status_',
        },
      },
    ],
    [],
  );

  return (
    <DrawerLayout
      pageHeadingProps={{
        title: 'Пакеты игрока',
        renderButtons: () => (
          <>
            {isLoading ? (
              <Skeleton />
            ) : (
              !isCurrentPackageError &&
              currentPackage?.package && (
                <>
                  {packageJustClosed && (
                    <Badge color="green" icon={<IconChecked />}>
                      Заявка на расчёт принята. Расчёт будет сделан в течение 72
                      часов
                    </Badge>
                  )}
                  {packageProfit > 0 &&
                    currentPackage.package.status === 'open' && (
                      <Button
                        color="secondary"
                        onClick={() => setCompletingPackageOpen(true)}
                      >
                        Закрыть текущий пакет
                      </Button>
                    )}
                  {completingPackageOpen && (
                    <Dialog
                      header="Закрыть пакет"
                      open={completingPackageOpen}
                      onClose={() => setCompletingPackageOpen(false)}
                    >
                      <ClosePackage
                        onSuccess={() => setPackageJustClosed(true)}
                        onClose={() => setCompletingPackageOpen(false)}
                      />
                    </Dialog>
                  )}
                </>
              )
            )}
            <Button
              view="contained"
              color="default"
              size="xlarge"
              className={css.drawerClose}
              icon={<IconClose fill="white" size="xlarge" />}
              onClick={closeDrawer}
            />
          </>
        ),
      }}
    >
      <DataGrid<FinanceCalculation, FilterPrototype>
        fetchData={getCalculations}
        fetchKey="finances/calculations"
        columns={columnDefs}
        pagination={false}
      />
    </DrawerLayout>
  );
};

export default PlayersPackages;
