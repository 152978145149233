import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Code, Error, IError, useGlobalState } from '@funfarm/kit';

import { IUser } from 'types';
import { verifyOTP } from 'actions';

import css from './signin.module.css';

export const TwoFA = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user] = useGlobalState<IUser>('user');

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const defaultValues = {
    code: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onSubmit = (formData: typeof defaultValues) => {
    setSubmitted(true);

    return verifyOTP({
      user_id: user?.id ?? '',
      otp: formData.code,
    })
      .then(() => {
        navigate(0);
      })
      .catch((error: IError) => {
        console.error(error);
        setError('Неверный код');
      })
      .finally(() => setSubmitted(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className={css.form}>
      <h1>{t('Enter code')}</h1>
      {error && <Error message={error} view="box" />}
      <Controller
        control={control}
        name="code"
        rules={{
          required: t('Passcode is required'),
          validate: (value) => value.length === 6,
        }}
        render={({ field }) => (
          <Code
            {...field}
            focus
            label={t('Code')}
            required
            error={!!errors.code}
            length={6}
          />
        )}
      />
      <div className={css.buttonWrapper}>
        <Button
          color="secondary"
          type="submit"
          loading={submitted}
          disabled={!isValid}
        >
          {t('Login')}
        </Button>
      </div>
    </form>
  );
};
