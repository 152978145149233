import { useContext } from 'react';

import { Row, Switcher } from '@funfarm/kit';
import Label from '@funfarm/kit/Radio/Label';
import Radio from '@funfarm/kit/Radio/Radio';
import RadioGroup from '@funfarm/kit/Radio/RadioGroup';

import {
  PaletteName,
  TourContext,
  TOURNAMENT_PALETTES,
} from 'components/Tournaments/TourProvider';

import ColorRow from './ColorRow';

import css from '../settings.module.scss';

const PaletteChoice = ({}) => {
  const {
    paletteLegendIsOpen,
    handlePaletteLegendIsOpen,
    palette: contextPalette,
    setPalette: setContextPalette,
  } = useContext(TourContext);

  return (
    <div className={css.paletteChoice}>
      <Row className={css.paletteChoice_title}>
        <h3>Легенда</h3>
        <Switcher
          value=""
          checked={paletteLegendIsOpen}
          onChange={handlePaletteLegendIsOpen}
        />
      </Row>
      <RadioGroup>
        {TOURNAMENT_PALETTES.map((paletteName) => (
          <Label key={paletteName} id={paletteName.toString()}>
            <div className={css.paletteChoice_item}>
              <Row className={css.paletteChoice_item_title}>
                <h4>Палитра {paletteName}</h4>
                <Radio
                  id={paletteName.toString()}
                  name="palette"
                  value={paletteName}
                  checked={paletteName === contextPalette}
                  onChange={() =>
                    setContextPalette?.(paletteName as PaletteName)
                  }
                />
              </Row>
              <ColorRow paletteName={paletteName} />
            </div>
          </Label>
        ))}
      </RadioGroup>
    </div>
  );
};

export default PaletteChoice;
