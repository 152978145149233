import React, { useEffect } from "react";
import { generateDashboardURL } from "actions/trainings";
import css from "./dashboard.module.scss";

export const Dashboard = () => {
  const [url, setUrl] = React.useState<string | undefined>(undefined);
  useEffect(() => {
    generateDashboardURL(1585, ["trainer"]).then((response) => {
      setUrl(response.url);
    }).catch(() => {
      alert("Failed to generate dashboard URL");
    });
  }, []);

  return (
    <iframe src={url} className={css.iframe} />
  );
};
