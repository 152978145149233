import { API } from "helpers/apiInstance";
import { ITrainingPlayer, ITrainingReportForm } from "types/index";

export const getSubjects = () => {
  return API.get<any, { filters: [] }>("/trainings/subjects").then(
    (response) => response
  );
};
export const getGroups = () => {
  return API.get<any, { filters: [] }>("/trainings/groups").then(
    (response) => response
  );
};
export const getPlayersInGroup = (
  groupID: string
): Promise<ITrainingPlayer[]> => {
  return API.get<ITrainingPlayer[], { filters: [] }>(
    `/trainings/groups/${groupID}/players`
  ).then((response) => response);
};
export const getAllPlayers = (
  group_type?: string
): Promise<ITrainingPlayer[]> => {
  return API.get<ITrainingPlayer[], { filters: [] }>(
    `/trainings/groups/players${group_type ? `?group_type=${group_type}` : ""}`
  ).then((response) => response);
};

export const createReport = (data: ITrainingReportForm): Promise<any> => {
  return API.post<ITrainingReportForm, any>(`/trainings/reports`, data);
};

export const generateDashboardURL = (
  dashboard_id: number,
  params: string[] = []
): Promise<any> => {
  return API.post<{ url: string }, any>(
    `/trainings/dashboard/${dashboard_id}/get-link`, { params }
  );
};
