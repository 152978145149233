import React from 'react';

import { Checkbox, Paper, Row } from '@funfarm/kit';
import { InputDateRange } from '@funfarm/kit/InputDate/InputDateRange';

import { WithdrawalsFilter } from 'types/finances';
import { FilterComponent } from 'types/table';

import css from 'components/Finances/finances.module.scss';

const TransfersFilter: FilterComponent<WithdrawalsFilter> = ({
  values,
  setValues,
}) => {
  // const{
  //         control,
  //         watch
  //     } = useForm({
  //         mode: 'onChange',
  //         reValidateMode: 'onChange',
  //     });

  //     const currentValues = watch();
  return (
    <Paper className={css.transfersFilter}>
      <Row gap={2}>
        <div>
          <InputDateRange
            placeholder="Период"
            className={css.dateRangeInput}
            value={[values?.date_start, values?.date_end]}
            // startDate={undefined}
            // endDate={undefined}
            label="Период"
            dateFormat="dd.MM.yyyy"
            labelPosition="inside"
            onChange={([startDate, endDate]) =>
              setValues({
                ...values,
                startDate,
                endDate,
              })
            }
          />
        </div>

        <Checkbox
          label="Пополнение"
          labelPosition="right"
          checked={values?.operationType === 'in'}
          onClick={() =>
            setValues({
              ...values,
              operationType: 'in',
            })
          }
        />
        <Checkbox
          label="Списание"
          labelPosition="right"
          checked={values?.operationType === 'out'}
          onClick={() =>
            setValues({
              ...values,
              operationType: 'out',
            })
          }
        />
      </Row>
    </Paper>
  );
};

export default TransfersFilter;
