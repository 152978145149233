import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { IError, Tabs } from '@funfarm/kit';

import { IFilterTemplate } from 'types';
import { getFilters } from 'actions';

import {
  defaultFilterParams,
  TourContext,
} from 'components/Tournaments/TourProvider';

import { EditTemplate } from './EditTemplate';

import css from './templates.module.scss';

interface IProps {}

export const FilterTemplates = (props: IProps) => {
  const {} = props;
  const { t } = useTranslation();
  const { setFilterParams } = useContext(TourContext);

  const { data } = useQuery<any, IError>({
    queryKey: ['filters'],
    queryFn: () => getFilters(),
  });

  const setDefaultTemplate = () => {
    setFilterParams && setFilterParams(defaultFilterParams);
  };

  return (
    <Tabs className={css.tabs} tabChecked="all">
      <Tabs.List className={css.tabsList}>
        <Tabs.Header index="default" onClick={setDefaultTemplate}>
          <div onClick={setDefaultTemplate}>{t('All filters')}</div>
        </Tabs.Header>
        {data &&
          data.map((template: IFilterTemplate) => {
            return (
              <EditTemplate
                key={template.id}
                template={{ ...template, ...template.value }}
              />
            );
          })}
        <EditTemplate />
      </Tabs.List>
    </Tabs>
  );
};
