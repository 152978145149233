import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  IconFilterColumn,
  IconHelpCircle,
  IconPalette,
  Switcher,
  tableStorage,
  TColumn,
  useOnClickOutside,
} from '@funfarm/kit';
import Popover from '@funfarm/kit/Popover/Popover';

import { Helper } from 'components/Helper';
import PaletteActive from 'components/Tournaments/TableColumnSettings/Palette/PaletteActive';
import PaletteChoice from 'components/Tournaments/TableColumnSettings/Palette/PaletteChoice';
import prepareTournamentColumns from 'components/Tournaments/TournamentsAll/tournaments.columns';
import { TourContext } from 'components/Tournaments/TourProvider';

import css from './settings.module.scss';

type TableColumnSettingsProps = {
  defaultColumns: string[];
};

export const mandatoryColumns = [
  'checkbox',
  'room',
  'room_abr',
  'name',
  'room',
  'bi',
  'bi_usd',
  'start_date',
  'actions',
];

export const TableColumnSettings: FC<TableColumnSettingsProps> = ({
  defaultColumns,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const tableName = 'tournaments';

  const { tableColumns, setTableColumns } = useContext(TourContext);
  const [open, setOpen] = useState<boolean>(false);

  useOnClickOutside(containerRef, async () => setOpen(false));

  const tournamentTableColumns = useMemo(
    () =>
      prepareTournamentColumns(t, true).map((column) => ({
        label: t(column.headerName || ''),
        name: column.field,
        default: defaultColumns.includes(column.field),
      })),
    [defaultColumns, t],
  );

  useEffect(() => {
    setTableColumns &&
      setTableColumns((prevColumns: unknown[]) => {
        const tableSettingsString = tableStorage.getItem();
        const tableSettings = tableSettingsString
          ? JSON.parse(tableSettingsString)
          : {};
        const defaultColumns =
          tableSettings[tableName] ??
          tournamentTableColumns
            .filter((col) => col.default)
            .map((col) => col.name);

        const uniqueColumns = new Set([...prevColumns, ...defaultColumns]);

        tableStorage.setItem(
          JSON.stringify({ ...tableSettings, [tableName]: [...uniqueColumns] }),
        );

        return [...uniqueColumns];
      });
  }, [tournamentTableColumns, setTableColumns, tableName]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setTableColumns &&
      setTableColumns((prevColumns: unknown[]) => {
        const tableSettingsString = tableStorage.getItem();
        const tableSettings = tableSettingsString
          ? JSON.parse(tableSettingsString)
          : {};
        const checkedColumns = checked
          ? [...prevColumns, value]
          : prevColumns.filter((col) => col !== value);

        tableStorage.setItem(
          JSON.stringify({ ...tableSettings, [tableName]: checkedColumns }),
        );

        return checkedColumns;
      });
  };

  const setDefault = () => {
    setTableColumns && setTableColumns(defaultColumns);
  };

  return (
    <>
      <Helper
        path="tourneys"
        buttonProps={{
          label: 'Памятка',
          iconLeft: <IconHelpCircle />,
        }}
        xlarge
      />
      <Popover
        horizontalPosition="right"
        styles={{ right: '6px' }}
        trigger={
          <Button
            iconLeft={<IconPalette size="large" />} // @todo insert proper icon from figma (add it to kit, icons)
            label="Настроить легенду"
          />
        }
      >
        <PaletteChoice />
      </Popover>
      <Button
        iconLeft={<IconFilterColumn size="large" />}
        label={t('Setup columns')}
        onClick={() => setOpen(true)}
      />
      {open && (
        <div className={css.settings} ref={containerRef}>
          <p className={css.settings__desc}>Настройка столбцов таблицы</p>
          <ul className={css.settings__list}>
            {tournamentTableColumns
              .filter((item) => !mandatoryColumns.includes(item.name))
              .map((item: TColumn) => {
                return (
                  <li key={item.name} className={css.settings__item}>
                    <span className={css.settings__text}>{item.label}</span>
                    <Switcher
                      value={item.name}
                      checked={
                        tableColumns
                          ? tableColumns.includes(item.name)
                          : item.default
                      }
                      onChange={onChange}
                    />
                  </li>
                );
              })}
          </ul>
          <span className={css.settings__default}>
            <Button view="text" color="secondary" onClick={setDefault}>
              Настроить по умолчанию
            </Button>
          </span>
        </div>
      )}
      <PaletteActive />
    </>
  );
};
