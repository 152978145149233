import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { IError } from '@funfarm/kit';

import { formatPeriod } from 'helpers/formatDates';
import { IInfoSummary } from 'types/index';
import { getSummary } from 'actions/index';

import { Indicators } from './Indicators';
import { Preflop } from './Preflop';
import { Video } from './Video';

import css from './statistic.module.scss';

export const Statistic = () => {
  const { data } = useQuery<IInfoSummary, IError>({
    queryKey: ['info', 'summary'],
    queryFn: getSummary,
  });

  return (
    <>
      {data?.first_hh_date && data?.last_hh_date && (
        <div className={css.statsPeriod}>
          {formatPeriod(data.first_hh_date, data.last_hh_date)}
        </div>
      )}
      <div className={css.pageGrid}>
        <Indicators />

        <Preflop />

        <Preflop />

        <Video />
      </div>
    </>
  );
};
