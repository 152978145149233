import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';

import { TourContext } from '@funfarm/lk/src/components/Tournaments/TourProvider';
import {
  ApiFetchFunctionNested,
  FilterPrototype,
  TableItemPrototype,
} from '@funfarm/lk/src/types/table';

import { IPaginationParams } from '../types';
import { RowClassParams } from 'ag-grid-community/dist/types/src/entities/gridOptions';

import Checkbox from '../Checkbox';
import { Loader } from '../Loader';
import { TableContext } from '../Table';

import columnTypes from './dataGrid.columnTypes';
import { darkTheme } from './dataGrid.themes';

import css from '../Table/table.module.scss';

type InfiniteLoadingProps<
  ItemType extends TableItemPrototype,
  SelectKey extends string,
> = {
  fetchData: ApiFetchFunctionNested<SelectKey, ItemType, FilterPrototype>;
  selectKey: keyof ItemType;
  columns: ColDef<ItemType>[];
  getRowClass?: (params: RowClassParams<ItemType>) => string;
  hideHeader?: boolean;
};

const InfiniteLoading = <
  ItemType extends TableItemPrototype,
  SelectKey extends string,
>(
  props: InfiniteLoadingProps<ItemType, SelectKey>,
) => {
  /********  PROPS & STATE  */
  const { fetchData, selectKey, columns, getRowClass } = props;
  const { ref, inView } = useInView();
  const { orderedBy, pagination, setRowsChecked } = useContext(TableContext);
  const { filterParams } = useContext(TourContext);
  const tableRef = useRef<AgGridReact<ItemType>>(null);
  const [selectAll, setSelectAll] = useState(false);

  /********  DATA  */
  const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['tourneys', filterParams, pagination, orderedBy],
      queryFn: ({ pageParam }) => {
        const paginationParams = (pageParam as IPaginationParams) ?? pagination;
        return fetchData(
          { ...filterParams, selected: false },
          paginationParams.skip,
          paginationParams.take,
          orderedBy,
        );
      },
      getNextPageParam: (lastPage, allPages) => {
        const totalLoaded = allPages.flatMap((page) => page[selectKey]).length;
        return totalLoaded < lastPage.total
          ? { take: pagination?.take, skip: totalLoaded }
          : undefined;
      },
      initialPageParam: pagination,
      refetchInterval: 5 * 60 * 1000,
    });
  const preparedData = useMemo(
    () => data?.pages.flatMap((page) => page[selectKey]),
    [data?.pages, selectKey],
  );
  useEffect(() => {
    if (inView && hasNextPage) {
      // Fix the moment when loader in the bottom is reached
      fetchNextPage(); // ...and load the next page
    }
  }, [inView, hasNextPage, fetchNextPage]);

  /********  SELECTION  */
  const handleSelectAll = useCallback(() => {
    setSelectAll(!selectAll);
    if (tableRef.current) {
      if (!selectAll) {
        tableRef.current.api.selectAll();
      } else {
        tableRef.current.api.deselectAll();
      }
    }
  }, [selectAll]);

  /********  RENDERING  */
  return (
    <div>
      <div className={css.willPlayRow}>
        <Checkbox
          label={t('All tourneys')}
          labelPosition="right"
          checked={selectAll}
          onClick={handleSelectAll}
        />
      </div>
      <AgGridReact<ItemType>
        ref={tableRef}
        // data
        loading={isLoading}
        // loading={isLoading || !columnState}
        rowData={preparedData}
        // columns
        columnDefs={columns}
        columnTypes={columnTypes}
        // selection
        getRowId={(params) => String(params.data.id)}
        rowSelection={{ mode: 'multiRow' }}
        onSelectionChanged={(params) => {
          const selectedRows = params.api.getSelectedRows();
          setRowsChecked && setRowsChecked(selectedRows);
        }}
        // behaviour
        suppressDragLeaveHidesColumns
        suppressRowHoverHighlight={true}
        suppressScrollOnNewData
        // appearance
        defaultColDef={{
          flex: 1, // Ensures all columns expand
          minWidth: 100,
          resizable: false,
          suppressMovable: true,
          sortable: false,
        }}
        headerHeight={0}
        theme={darkTheme}
        getRowClass={getRowClass}
        domLayout="autoHeight"
      />

      {!isLoading && data && (
        <div ref={ref}>
          {isFetchingNextPage && (
            <Loader
              size="small"
              caption="Fetching table data..."
              className={css.loader}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InfiniteLoading;
