import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconStairs = (props: IconProps) => {
  return (
    <Icon id="IconFilterColumn" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5C16 4.44772 16.4477 4 17 4H22C22.5523 4 23 4.44772 23 5C23 5.55228 22.5523 6 22 6H18V10C18 10.5523 17.5523 11 17 11H13V15C13 15.5523 12.5523 16 12 16H8V20C8 20.5523 7.55228 21 7 21H2C1.44772 21 1 20.5523 1 20C1 19.4477 1.44772 19 2 19H6V15C6 14.4477 6.44772 14 7 14H11V10C11 9.44772 11.4477 9 12 9H16V5Z"
      />
    </Icon>
  );
};

export default IconStairs;
