import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { BalanceItem } from 'types/finances';
import {
  getBalances,
  getCurrentCalculation,
  getFinanceMetrics,
} from 'actions/finances';

const useBalancesAndBrm = () => {
  const balances = useQuery({
    queryKey: ['finances', 'balances'],
    queryFn: () => getBalances(),
    refetchInterval: 60000,
  });
  const metrics = useQuery({
    queryKey: ['finances', 'metrics'],
    queryFn: () => getFinanceMetrics(),
    refetchInterval: 60000,
  });
  const currentPackage = useQuery({
    queryKey: ['finances', 'calculation/current'],
    queryFn: () => getCurrentCalculation(),
    refetchInterval: 60000,
  });

  const [completed, inTheWay] = useMemo(() => {
    const result: [BalanceItem[], BalanceItem[]] = [[], []];
    balances.data?.rooms.forEach((item) => {
      if (item.to_way) result[1].push(item);
      else result[0].push(item);
    });
    return result;
  }, [balances.data]);

  return {
    metrics: metrics.data?.data,
    balances: completed,
    inTheWay,
    toAddToWay: balances.data?.no_way_to_add,
    currentPackage: currentPackage.data,

    isLoading:
      balances.isLoading || metrics.isLoading || currentPackage.isLoading,
    isBalancesError: balances.isError,
    isMetricsError: metrics.isError,
    isCurrentPackageError: currentPackage.isError,

    refetch: () => {
      balances.refetch();
      metrics.refetch();
      currentPackage.refetch();
    },

    getRoomDetails: (roomId: number) =>
      completed.find((item) => item.room_id === roomId),
  };
};

export default useBalancesAndBrm;
