import axios, { AxiosProgressEvent } from 'axios';

import { getRandom, timer } from '@funfarm/kit';

import { API } from 'helpers';
import {
  EducationBlock,
  EducationStep,
  EPeriod,
  ETableSize,
  I3BetStatsData,
  I4betSQuizData,
  IABIProgressData,
  ICCvRStatsData,
  IDailyScheduleData,
  IEVProgressData,
  IEVSkills,
  IFold3BetData,
  IFoldBBData,
  IInfoSummary,
  IMTTProgressData,
  IMultiStatsData,
  IMultiWayStatsData,
  INetworkData,
  INetworkProfits,
  IPlayerHistory,
  IPlayerStatistics,
  IPlayerStatisticsFilters,
  IPlayerTrolleyStats,
  IPostflopSummary,
  IPostflopTable,
  IPreflopSummary,
  IRaiseReactionsData,
  IRangProgressData,
  IRFIBlindData,
  IRFIMonthData,
  IRvCCStatsData,
  ISelectEstimates,
  ISelectRanks,
  ISelectSummary,
  ITBetStatsData,
  ITokens,
  IUser,
  ModuleWithSteps,
  PutPermissionsBody,
} from 'types';

export * from './tournaments';

interface ILoginPost {
  username: string;
  password: string;
}

interface IRegisterPost {
  username: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
  email: string;
  telegram: string;
}

interface ISetPasswordPost {
  password: string;
  password_confirmation: string;
  token: string;
}

export const logIn = (formData: ILoginPost) => {
  return API.post<ILoginPost, ITokens>('/auth/sign-in', formData);
};

export const logInCheck = (formData: ILoginPost) => {
  return API.post<ILoginPost, ITokens>('/auth/check/login', formData);
};

export const register = (formData: IRegisterPost) => {
  return API.post<ILoginPost, ITokens>('/auth/sign-up', formData);
};

export const resetPassword = ({ email }: { email: string }) => {
  return API.post<any, any>('/auth/reset-password', {
    email,
  });
};

export const resendConfirmation = () => {
  return API.post<any, any>('/auth/resend-confirmation');
};

export const setPassword = (r: ISetPasswordPost) => {
  return API.post<any, any>(`/auth/reset-password/${r.token}`, {
    password: r.password,
    password_confirmation: r.password_confirmation,
  });
};

export const confirmEmail = ({ token }: { token: string }) => {
  return API.post<any, any>(`/auth/confirm-email/${token}`);
};

export const verifyOTP = ({
  user_id,
  otp,
}: {
  user_id: string;
  otp: string;
}) => {
  return API.post<any, any>(`/auth/verify-otp`, {
    id: user_id,
    otp,
  });
};

export const getUser = () => {
  return API.get<any, IUser>('/auth/profile');
};

export const uploadFile = async (
  file: File,
  cb: (e: AxiosProgressEvent) => void | undefined,
): Promise<string> => {
  // Шаг 1: Получение предподписанного URL для загрузки файла
  const response = await API.post<any, Record<string, any>>(
    '/files/generate_presigned_url',
    {
      file_name: file.name,
    },
  );
  const presignedUrl = response.presigned_url;

  // Шаг 2: Загрузка файла на S3 по полученному URL
  try {
    await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type || 'application/octet-stream',
      },
      onUploadProgress: (progressEvent) => (cb ? cb(progressEvent) : false),
    });
    console.log('Загрузка завершена');
  } catch (error) {
    console.error('Ошибка при загрузке файла:', error);
  }
  return response.download_url;
};

export const uploadComplete = (
  upload_id: string,
  filename: string,
  parts: any[],
) => {
  return API.post(`/files/upload/${upload_id}/complete`, { parts, filename });
};

export const getSummary = () => {
  return API.get<any, IInfoSummary>('/info/summary');
};

export const getPlayerTrolley = () => {
  return API.get<any, IPlayerTrolleyStats>('/player/trolley-stats');
};

export const getPlayerHistory = () => {
  return API.get<any, IPlayerHistory>('/player/history');
};

export const getNetworksProfit = (room: string) => {
  return API.get<any, INetworkProfits>(`/info/network-profits`, {
    params: { network: room.toLowerCase() },
  });
};

export const getRangProgress = (startDate?: string) => {
  return API.get<any, IRangProgressData>('/player/rang-progress', {
    params: { start_date: startDate },
  });
};

export const getEVProgress = (startDate?: string) => {
  return API.get<any, IEVProgressData>('/player/ev-progress', {
    params: { start_date: startDate },
  });
};

export const getABIProgress = (startDate?: string) => {
  return API.get<any, IABIProgressData>('/player/abi-progress', {
    params: { start_date: startDate },
  });
};

export const getMTTProgress = (startDate?: string) => {
  return API.get<any, IMTTProgressData>('/player/mtt-progress', {
    params: { start_date: startDate },
  });
};

export const getSelectSummary = (period?: keyof typeof EPeriod) => {
  return API.get<any, ISelectSummary>('/select/select-summary', {
    params: { period },
  });
};

export const getSelectRanks = (period?: keyof typeof EPeriod) => {
  return API.get<any, ISelectRanks>('/select/rank', { params: { period } });
};

export const getSelectEstimates = (period?: keyof typeof EPeriod) => {
  return API.get<any, ISelectEstimates>('/select/estimates', {
    params: { period },
  });
};

export const getPreflopSummary = () => {
  return API.get<any, IPreflopSummary>('/preflop/summary-100k');
};

export const getPlayerStatistics = (
  params: Partial<IPlayerStatisticsFilters>,
) => {
  return API.get<any, IPlayerStatistics>('/statistics', { params });
};

export const getPlayerStatisticsPreflop = (
  params: Partial<IPlayerStatisticsFilters>,
) => {
  return API.get<any, IPlayerStatistics>('/statistics/preflop', { params });
};

export const getPlayerStatisticsPostflop = (
  params: Partial<IPlayerStatisticsFilters>,
) => {
  return API.get<any, IPlayerStatistics>('/statistics/postflop', { params });
};

export const getPostflopSummary = () => {
  return timer<IPostflopSummary>(
    {
      max6: getRandom(2, 6),
      max6_hands_count: getRandom(150000, 155000),
      max9: getRandom(3, 9),
      max9_hands_count: getRandom(30000, 55000),
      month_3_table_6_hands: getRandom(30000, 55000),
      current_hands: getRandom(50000, 65000),
      current_hands_count: getRandom(-8000, -12000),
      last_hands: getRandom(30000, 50000),
      last_hands_count: getRandom(-1000, -2000),
      ev_early: getRandom(6, 11, 1),
      ev_early_hands_count: getRandom(100000, 130000),
      ev_stat_room: getRandom(3, 12),
      ev_stat_room_hands_count: getRandom(50000, 100000),
      ev_unstat_room: getRandom(2, 10),
      ev_unstat_room_hands_count: getRandom(30000, 50000),
    },
    1000,
  );

  // return API.get<any, IIndicators>('/info/gamification');
};

export const getPostflopSkills = () => {
  return timer<any>(
    [
      getRandom(1000, 5000),
      getRandom(1000, 5000),
      getRandom(1000, 5000),
      getRandom(1000, 5000),
      getRandom(1000, 5000),
      getRandom(1000, 5000),
    ],
    1000,
  );

  // return API.get<any, IIndicators>('/info/gamification');
};

export const getRvBBStats = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, IPostflopTable>('/postflop/rvbb-stats', {
    params: { table_size },
  });
};

export const getBBRStats = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, IPostflopTable>('/postflop/bbr-stats', {
    params: { table_size },
  });
};

export const getMultiWayStats = (
  table_size?: keyof typeof ETableSize,
  period?: keyof typeof EPeriod,
) => {
  return API.get<any, IMultiWayStatsData[]>('/postflop/multiway-stats', {
    params: { period, table_size },
  });
};

export const getRvCCStats = (
  table_size?: keyof typeof ETableSize,
  period?: keyof typeof EPeriod,
) => {
  return API.get<any, IRvCCStatsData[]>('/postflop/rvcc-stats', {
    params: { period, table_size },
  });
};

export const getCCvRStats = (
  table_size?: keyof typeof ETableSize,
  period?: keyof typeof EPeriod,
) => {
  return API.get<any, ICCvRStatsData[]>('/postflop/ccvr-stats', {
    params: { period, table_size },
  });
};

export const get3BetStats = (
  table_size?: keyof typeof ETableSize,
  period?: keyof typeof EPeriod,
) => {
  return API.get<any, I3BetStatsData[]>('/postflop/3bet-stats', {
    params: { period, table_size },
  });
};

export const getTBetStats = (
  table_size?: keyof typeof ETableSize,
  period?: keyof typeof EPeriod,
) => {
  return API.get<any, ITBetStatsData[]>('/postflop/3bet-stats', {
    params: { period, table_size },
  });
};

export const getMultiStats = (
  table_size?: keyof typeof ETableSize,
  period?: keyof typeof EPeriod,
) => {
  return API.get<any, IMultiStatsData[]>('/postflop/multiway-stats', {
    params: { period, table_size },
  });
};

export const getDailySchedule = (period: keyof typeof EPeriod) => {
  return API.get<any, IDailyScheduleData>(`/select/schedule?period=${period}`);
};

export const getNetwork = (period: keyof typeof EPeriod) => {
  return API.get<any, INetworkData>(`/select/network-load?period=${period}`);
};

export const getRaiseReactions = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, IRaiseReactionsData>('/preflop/raise-reactions', {
    params: { table_size },
  });
};

export const get4betSquiz = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, I4betSQuizData>('/preflop/4bet-squiz', {
    params: { table_size },
  });
};

export const getRFIByMonth = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, IRFIMonthData>('/preflop/rfi-by-month', {
    params: { table_size },
  });
};

export const getRFIByBlind = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, IRFIBlindData>('/preflop/rfi-by-blind', {
    params: { table_size },
  });
};

export const getFold3Bet = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, IFold3BetData>('/preflop/fold-3bet', {
    params: { table_size },
  });
};

export const getFoldBB = (table_size?: keyof typeof ETableSize) => {
  return API.get<any, IFoldBBData>('/preflop/fold-bb', {
    params: { table_size },
  });
};

export const getSkills = () => {
  return API.get<any, IEVSkills>(`/preflop/skills`);
};

export const createPermission = (
  datetime: number | Date,
  room_id: number | string,
  bi?: number | string | null,
  comment?: string | null,
  name?: string | null,
  tourney_source?: string,
  tourney_id?: number | string | null,
) =>
  API.post<any, any>('/check/tourneys/permissions/requests', {
    datetime,
    room_id,
    bi,
    comment,
    name,
    tourney_source,
    tourney_id,
  });

export const getAvailablePermissions = (
  datetime: number,
  room_id: number,
  bi: number,
) =>
  API.get<any, any>('/check/tourneys/permissions/available', {
    params: {
      datetime,
      room_id,
      bi,
    },
  });

export const putPermissionsRequest = (
  id: string | number,
  body: PutPermissionsBody,
) => {
  return API.put<any, any>(`/check/tourneys/permissions/requests/${id}`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deletePermissionsRequest = (id: string | number) => {
  return API.delete<any>(`/check/tourneys/permissions/requests/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getModulesWithStatus = () =>
  API.get<any, EducationBlock[]>('/gamification/way/modules_with_status');

export const getModuleById = (moduleId: string) =>
  API.get<any, ModuleWithSteps>(
    `/gamification/way/modules/${moduleId}/steps_with_status`,
  );

export const getEducationStepById = (moduleId: string, stepId: string) =>
  API.get<any, { module: ModuleWithSteps; step: EducationStep }>(
    `/gamification/way/modules/${moduleId}/steps/${stepId}`,
  );
