/// <reference types="vite-plugin-svgr/client" />

declare global {
  interface Window {
    env: any;
  }
}

type EnvType = Record<string, string>;

export const env: EnvType = import.meta.env.PROD ? window.env : import.meta.env;
