import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isToday,
  parseISO,
} from 'date-fns';
import { t } from 'i18next';

import i18n, { localeMap } from './i18n';

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timer: NodeJS.Timeout | null = null;

  return function (...args: Parameters<T>) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const displayStartDate = (
  startDate: string | null,
  endDate?: string | null,
) => {
  if (!startDate || !endDate) return null;

  const now = new Date();
  const start = parseISO(startDate ?? '');
  const end = parseISO(endDate ?? '');

  if (isBefore(now, startDate)) {
    const minutesToStart = differenceInMinutes(start, now);

    if (minutesToStart < 60) {
      return `${t('In')} ${minutesToStart} ${t('min', { count: minutesToStart })}`;
    }

    if (isToday(startDate)) return '';

    return '';
  }

  if (isAfter(now, end)) return t('Finished');

  const minutesElapsed = differenceInMinutes(now, start);

  if (minutesElapsed < 60)
    return `${t('Running')} ${minutesElapsed} ${t('min', { count: minutesElapsed })}`;

  return `${t('Running')}`;
};

export const displayDate = (endDate: string | null) => {
  if (!endDate) return null;

  const date = parseISO(endDate ?? '');

  if (isToday(endDate))
    return format(date, 'HH:mm', { locale: localeMap[i18n.language] });

  return format(date, 'd LLL HH:mm', { locale: localeMap[i18n.language] });
};

const nounsForms = {
  tasks: ['задание', 'задания', 'заданий'],
  minutes: ['минута', 'минуты', 'минут'],
  modules: ['раздел', 'раздела', 'разделов'],
};

export const getWordEnding = (
  count: number,
  noun: keyof typeof nounsForms,
): string => {
  if (count % 100 >= 11 && count % 100 <= 19) {
    return nounsForms[noun][2];
  }

  const remainder = count % 10;
  if (remainder === 1) {
    return nounsForms[noun][0];
  } else if (remainder >= 2 && remainder <= 4) {
    return nounsForms[noun][1];
  } else {
    return nounsForms[noun][2];
  }
};
