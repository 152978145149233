import React from 'react';

import { TableCell, TableRow } from '@funfarm/kit';

import { ILimitsParams } from 'types/index';

import { mapTypeToString } from './TournamentsLimits';

import css from './limits.module.scss';

interface LimitsTableRowProps {
  limit: ILimitsParams;
}

export const LimitsTableRow: React.FC<LimitsTableRowProps> = ({ limit }) => {
  return (
    <TableRow className={css.limitsRow}>
      <TableCell colspan={2}>{mapTypeToString(limit.type)}</TableCell>
      <TableCell colspan={2}>{limit.reg6}</TableCell>
      <TableCell colspan={2}>{limit.reg9}</TableCell>
      <TableCell colspan={2}>{limit.turbo6}</TableCell>
      <TableCell colspan={2}>{limit.turbo9}</TableCell>
    </TableRow>
  );
};
