import React, { useContext } from 'react';

import { Dialog } from '@funfarm/kit';

import { PermissionTable } from 'components/Tournaments/TournamentsPermissions/PermissionTable';

import { TourContext } from '../TourProvider';

import NewPermission from './NewPermission';
import PlayedTournament from './PlayedTournament';

import tournamentsCss from '../tournaments.module.scss';

export const TournamentsPermissions = () => {
  const {
    createPermissionIsOpen,
    closeCreatePermission,
    playedTournamentIsOpen,
    closePlayedTournament,
  } = useContext(TourContext);

  return (
    <section className={tournamentsCss.tournaments_section}>
      <h2>Разрешения на турниры</h2>
      <PermissionTable />
      <Dialog
        header="Разрешение на турнир"
        open={createPermissionIsOpen}
        onClose={closeCreatePermission}
      >
        <NewPermission onClose={closeCreatePermission} />
      </Dialog>
      <Dialog
        header="Внести результаты турнира от себя"
        open={playedTournamentIsOpen}
        onClose={closePlayedTournament}
      >
        <PlayedTournament onClose={closePlayedTournament} />
      </Dialog>
    </section>
  );
};
