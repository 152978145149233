import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery } from '@tanstack/react-query';
import { ChartOptions } from 'chart.js';
import { format } from 'date-fns';

import { IError, Loader } from '@funfarm/kit';

import { defaultChartOptions } from 'helpers';
import i18n, { localeMap } from 'helpers/i18n';
import { IEVProgressData } from 'types';
import { getEVProgress } from 'actions';

export const EVByMonthChart = () => {
  const { isLoading, data } = useQuery<IEVProgressData, IError>({
    queryKey: ['ev-progress'],
    queryFn: () => getEVProgress(),
  });

  const chartData = useMemo(() => {
    const labels = data?.progress.map((pr) =>
      format(new Date(pr.date), 'LLL`yy', { locale: localeMap[i18n.language] }),
    );
    return {
      labels: labels,
      datasets: [
        {
          pointRadius: 0,
          lineTension: 0.4,
          data: data?.progress.map((pr) => pr.ev),
          fill: false,
          borderColor: '#FFDC5D',
        },
      ],
    };
  }, [data]);

  const chartOptions = useMemo(() => {
    return {
      ...defaultChartOptions,
      scales: {
        ...defaultChartOptions.scales,
        x: {
          ...defaultChartOptions.scales?.x,
          ticks: {
            ...defaultChartOptions.scales?.x?.ticks,
            callback: (_value: string, index: number) =>
              chartData?.labels?.[index],
          },
        },
      },
    };
  }, [chartData]);

  if (isLoading)
    return <Loader layout size="small" style={{ color: '#FFDC5D' }} />;

  return (
    <Line options={chartOptions as ChartOptions<'line'>} data={chartData} />
  );
};
