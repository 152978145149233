import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RowClassParams } from 'ag-grid-community';
import classNames from 'classnames';

import { IOrderedBy, TableProvider, useTable } from '@funfarm/kit';
import DataGridWithKey from '@funfarm/kit/DataGrid/DataGridWithKey';
import InfiniteLoading from '@funfarm/kit/DataGrid/InfiniteLoading';

import { ITournament } from 'types/index';
import { getSchedule } from 'actions/tournaments';

import { FloatingAction } from 'components/Tournaments/Fab';
import prepareTournamentColumns from 'components/Tournaments/TournamentsAll/tournaments.columns';
import { TourContext } from 'components/Tournaments/TourProvider';

import useQueryParams from '../../../hooks/useQueryParams';

import { TournamentsFilters } from './TournamentsFilters';

import paletteCss from '../TableColumnSettings/settings.module.scss';
import css from './table.module.scss';

export const TournamentsAllNew = () => {
  const { palette, tableColumns } = useContext(TourContext);
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const tableRef = useRef(null);
  const tableProps = useTable({
    pagination: { take: 50, skip: 0 },
    order:
      queryParams.orderBy && queryParams.orderType
        ? ({ [queryParams.orderBy]: queryParams.orderType } as IOrderedBy)
        : { start_date: 'asc' },
  });

  const willPlayColumns = useMemo(
    () =>
      prepareTournamentColumns(t).filter((item) =>
        tableColumns.includes(item.field),
      ),
    [t, tableColumns],
  );
  const allTourneyColumns = useMemo(
    () =>
      prepareTournamentColumns(t).filter((item) =>
        tableColumns.includes(item.field),
      ),
    [t, tableColumns],
  );

  return (
    <TableProvider tableRef={tableRef} {...tableProps}>
      <TournamentsFilters />

      <div className={css.selectedTable}>
        <DataGridWithKey<ITournament, 'tourneys'>
          fetchData={getSchedule}
          selectKey="tourneys"
          fetchKey={['tourneys', 'selected']}
          columns={willPlayColumns}
          getRowClass={(p) =>
            classNames(
              css.tournamentsGridRow,
              paletteCss[`color-v2-${p?.data?.color}-${palette}`],
            )
          }
          pagination={false}
          defaultFilters={{
            selected: true,
          }}
        />
      </div>

      <div className={css.allTables}>
        <InfiniteLoading<ITournament, 'tourneys'>
          fetchData={getSchedule}
          selectKey="tourneys"
          columns={allTourneyColumns}
          getRowClass={(p: RowClassParams<ITournament>) =>
            classNames(
              css.tournamentsGridRow,
              paletteCss[`gradient-${p?.data?.color}-${palette}`],
              paletteCss[`color-v2-${p?.data?.color}-${palette}`],
            )
          }
        />
      </div>

      <FloatingAction open={true} />
    </TableProvider>
  );
};
