import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, IconPlus } from '@funfarm/kit';

import PageHeading from 'components/PageHeading/PageHeading';
import { defaultTournamentColumns } from 'components/Tournaments/TournamentsAll/tournaments.columns';

import { TableColumnSettings } from './TableColumnSettings';
import { TourContext } from './TourProvider';

import css from './tournaments.module.scss';

export const TournamentsHeader = () => {
  const { openCreatePermission } = useContext(TourContext);
  const location = useLocation();

  const renderButtons = () => {
    switch (location.pathname) {
      case '/tournaments':
      case '/tournaments/new':
      case '/tournaments/beta':
        return (
          <>
            {/*<Button iconLeft={<IconDocumentCopy size="large" />}>Копировать таблицу</Button>*/}
            <TableColumnSettings defaultColumns={defaultTournamentColumns} />
          </>
        );
      case '/tournaments/permissions':
        return (
          <Button
            onClick={openCreatePermission}
            iconLeft={<IconPlus />}
            color="secondary"
            className={css.buttonPermission}
          >
            Запросить разрешение
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <PageHeading
      tabs={[
        { title: 'Все', to: '/tournaments' },
        { title: 'Разрешения на турниры', to: '/tournaments/permissions' },
        { title: 'Лимиты для румов', to: '/tournaments/limits' },
      ]}
      title="ТУРНИРЫ"
      renderButtons={renderButtons}
    />
  );
};
