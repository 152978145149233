import { generatePath, useParams } from 'react-router-dom';

import { IRoute } from 'types';

export const preparePath = (
  children: IRoute[],
  parentPath?: string,
  parentRoute?: IRoute,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams();

  return children.map((route: IRoute) => {
    if (!route.handle) route.handle = {};

    let handlePath = route.path;

    if (route.path) {
      const slugs = [...route.path.matchAll(/:(\w+)/g)];

      if (slugs.some((slug) => params[slug[1]])) {
        try {
          /**
           * TODO: fix issue with multiple dynamic routes and remove try catch
           * example:
           * /education/:id/tasks/:taskId
           * /education/:id
           */

          handlePath = generatePath(
            route.path,
            params as { [x: string]: string | null },
          );
        } catch (error) {
          console.log(error);
        }
      }
    }

    route.handle.path = route.index
      ? parentPath
      : parentPath
        ? `${parentPath}${parentPath.endsWith('/') ? '' : '/'}${handlePath}`
        : `${handlePath}`;

    // nested permissions
    if (route.handle.permission === undefined)
      route.handle.permission = parentRoute?.handle?.permission;

    // nested layout
    if (route.handle.layout === undefined)
      route.handle.layout = parentRoute?.handle?.layout;

    route.children =
      route.children && preparePath(route.children, route.handle?.path, route);

    return route;
  });
};
