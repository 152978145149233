import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Error, IError, Input, Row } from '@funfarm/kit';
import MoneyInput from '@funfarm/kit/Input/MoneyInput';

import { BalanceItem, FinanceRoom } from 'types/finances';
import { createRequest } from 'actions/finances';

import AccountSelect from 'components/Select/AccountSelect';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';
import useFinanceRoom from '../../../hooks/useFinanceRoom';

import css from '../finances.module.scss';

type CreateRequestProps = {
  balance?: BalanceItem;
  onClose: () => void;
  onSuccess?: () => void;
};

const CreateRequest: FC<CreateRequestProps> = ({
  balance,
  onClose,
  onSuccess,
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { refetch } = useBalancesAndBrm();

  const accountId = watch('account');
  const accountDetails = useFinanceRoom(accountId);

  return (
    <div className={css.closePackageForm}>
      <form
        onSubmit={handleSubmit((formData) => {
          setSubmitted(true);
          createRequest(
            balance ? balance.room_id : formData.account,
            formData.amount,
            formData.comment,
          )
            .then((response) => {
              if (response?.errors) {
                setError(Object.values(response.errors).join('\n'));
              } else {
                refetch();
                onSuccess && onSuccess();
                onClose();
              }
            })
            .catch((error: IError) => {
              setError(error.message);
            })
            .finally(() => setSubmitted(false));
        })}
        noValidate
        className={css.form}
      >
        {error && <Error message={error} view="box" />}
        {balance ? (
          <Input label="Выбор счёта" value={balance.room_title} disabled />
        ) : (
          <>
            <AccountSelect
              control={control}
              error={!!errors.account}
              filterFunction={(room: FinanceRoom) => !!room.allowtransfer}
            />
            <Input
              label="Данные счёта"
              value="Здесь отобразятся данные счёта"
              disabled
            />
          </>
        )}
        <Controller
          control={control}
          name="amount"
          render={({ field }) => (
            <MoneyInput
              {...field}
              label="Сумма"
              placeholder="Введите нужную сумму"
              currency={
                balance
                  ? balance.currency_symbol
                  : accountDetails?.currency || ''
              }
              error={!!errors.amount}
            />
          )}
        />
        <Controller
          control={control}
          name="comment"
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Можете дополнить ваш запрос комментарием"
              // labelPosition="inside"
            />
          )}
        />
        <Row className={css.formButtons}>
          <Button
            color="default"
            loading={submitted}
            label="Отмена"
            onClick={onClose}
          />
          <Button
            color="secondary"
            type="submit"
            loading={submitted}
            disabled={!isValid}
            label="Создать запрос"
          />
        </Row>
      </form>
    </div>
  );
};

export default CreateRequest;
