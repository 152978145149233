import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Error,
  IconPlus,
  IError,
  Input,
  InputDate,
  Row,
  Select,
} from '@funfarm/kit';
import NumberInput from '@funfarm/kit/Input/NumberInput';

import { useOptions } from 'helpers/useOptions';
import { createPermission, getAvailablePermissions, getRooms } from 'actions';

import css from 'components/Tournaments/tournaments.module.scss';

interface FormValues {
  datetime: number | Date;
  room_id: number | string;
  tourney_id: number | string;
  tourney_name: string;
  bi: number | string;
  comment: string;
}

interface CreatePermissionProps {
  onClose?: () => void;
  onSuccess?: () => void;
}

const defaultValues: FormValues = {
  datetime: new Date(),
  room_id: '',
  tourney_id: '',
  tourney_name: '',
  bi: '',
  comment: '',
};

const CreatePermission: FC<CreatePermissionProps> = ({
  onClose,
  onSuccess,
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [tournamentsData, setTournamentsData] = useState<any[]>([]);
  const [isTournamentsLoading, setIsTournamentsLoading] = useState(false);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: roomsData,
    isLoading: isRoomsLoading,
    error: roomsError,
  } = useQuery<any, IError>({
    queryKey: ['rooms'],
    queryFn: () => getRooms(),
  });
  const roomsOptions = useOptions(roomsData, 'id', 'name');

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  const [selectedDate, selectedRoom, selectedBi] = watch([
    'datetime',
    'room_id',
    'bi',
  ]);
  const selectedTourneyId = useWatch({ control, name: 'tourney_id' });

  const selectedTournament = useMemo(() => {
    return tournamentsData.find(
      (tourney) => tourney.id === Number(selectedTourneyId),
    );
  }, [selectedTourneyId, tournamentsData]);

  useEffect(() => {
    setValue('tourney_id', '');
  }, [selectedDate, selectedRoom, selectedBi, setValue]);

  useEffect(() => {
    if (!!selectedDate && !!selectedRoom && !!selectedBi) {
      setIsTournamentsLoading(true);
      const datetimeInMili =
        selectedDate instanceof Date ? selectedDate.getTime() : selectedDate;

      getAvailablePermissions(
        datetimeInMili,
        Number(selectedRoom),
        Number(selectedBi),
      )
        .then((response) => {
          setTournamentsData(response.tourneys);
        })
        .catch((err: IError) => {
          setError(err.message);
        })
        .finally(() => setIsTournamentsLoading(false));
    }
  }, [selectedDate, selectedRoom, selectedBi]);

  const tournamentsOptions = useMemo(() => {
    return tournamentsData
      .filter((item) => item.id !== undefined && item.name !== undefined)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
  }, [tournamentsData]);

  if (roomsError) {
    return <Error message={t('Failed to load rooms')} />;
  }

  const onSubmit = handleSubmit((formData) => {
    setSubmitted(true);
    setError(undefined);

    const selectedTournament = tournamentsData.find(
      (tourney) => tourney.id === Number(formData.tourney_id),
    );

    if (!selectedTournament && !formData.tourney_name) {
      setError(t('Tourney name is required'));
      setSubmitted(false);
      return;
    }

    const datetimeInMili =
      selectedDate instanceof Date ? selectedDate.getTime() : selectedDate;
    const room_id = Number(formData.room_id);
    const bi = formData.bi;
    const comment = formData.comment;
    const name = !selectedTournament
      ? formData.tourney_name
      : selectedTournament.name;
    const tourney_source = !selectedTournament
      ? null
      : selectedTournament.source;

    createPermission(
      datetimeInMili,
      room_id,
      bi,
      comment,
      name,
      tourney_source,
      selectedTournament ? Number(formData.tourney_id) : undefined,
    )
      .then((response) => {
        if (response?.errors) {
          setError(Object.values(response.errors).join('\n'));
        } else {
          queryClient.invalidateQueries({ queryKey: ['permissions'] });
          onSuccess?.();
          onClose?.();
        }
      })
      .catch((error: IError) => {
        setError(error.message);
      })
      .finally(() => setSubmitted(false));
  });

  return (
    <form onSubmit={onSubmit}>
      {error && <Error message={error} view="box" />}
      <Controller
        control={control}
        name="datetime"
        rules={{ required: t('This field is required') }}
        render={({ field, fieldState: { error } }) => (
          <div className={css.dateWrapper}>
            <InputDate
              {...field}
              showTimeInput
              timeInputLabel={t('Time')}
              dateFormat="dd.MM.yyyy HH:mm"
              minDate={new Date()}
              label={t('Date and time')}
              labelPosition="top"
            />
            {error && <Error message={error.message} view="inline" />}
          </div>
        )}
      />

      <Controller
        control={control}
        name="room_id"
        rules={{ required: t('This field is required') }}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              {...field}
              options={roomsOptions}
              label={t('Rooms')}
              labelPosition="top"
              loading={isRoomsLoading}
            />
            {error && <Error message={error.message} view="inline" />}
          </>
        )}
      />

      <Controller
        control={control}
        name="bi"
        render={({ field }) => (
          <>
            <NumberInput
              {...field}
              label={t('Buy-in')}
              value={field.value ? field.value.toString() : ''}
              onChange={(value) => field.onChange(value)}
            />
          </>
        )}
      />

      {tournamentsOptions.length > 0 ? (
        <Controller
          control={control}
          name="tourney_id"
          render={({ field, fieldState: { error } }) => (
            <>
              <Select
                {...field}
                style={{ marginBottom: selectedTourneyId && 0 }}
                options={tournamentsOptions}
                loading={isTournamentsLoading}
                label={t('Tourney')}
                labelPosition="top"
                disabled={
                  !selectedDate ||
                  !selectedRoom ||
                  !selectedBi ||
                  isTournamentsLoading
                }
              />
              {selectedTournament && (
                <div className={css.form_select_suggestions}>
                  <div className={css.select_suggestions_date}>
                    {t('Time')}:
                    <div className={css.select_suggestions_value}>
                      {new Date(selectedTournament.date * 1000).toLocaleString(
                        'ru-RU',
                        {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        },
                      )}
                    </div>
                  </div>
                  <div className={css.select_suggestions_buyin}>
                    {t('Buy-in')}:
                    <div className={css.select_suggestions_value}>
                      {Number(selectedTournament.rake) +
                        Number(selectedTournament.stake)}
                    </div>
                  </div>
                </div>
              )}
              {error && <Error message={error.message} view="inline" />}
            </>
          )}
        />
      ) : (
        <Controller
          control={control}
          name="tourney_name"
          render={({ field }) => (
            <Input
              {...field}
              labelPosition="top"
              label={t('Tourney')}
              disabled={
                !selectedDate ||
                !selectedRoom ||
                !selectedBi ||
                isTournamentsLoading
              }
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="comment"
        render={({ field }) => (
          <Input {...field} labelPosition="top" label={t('Comment')} />
        )}
      />

      <Row className={css.formButtons}>
        <Button
          color="default"
          label="Отмена"
          loading={submitted}
          onClick={onClose}
        />
        <Button
          iconLeft={<IconPlus />}
          color="secondary"
          type="submit"
          label="Запросить"
          loading={submitted}
          disabled={!isValid || !selectedDate || !selectedRoom || !selectedBi}
        />
      </Row>
    </form>
  );
};

export default CreatePermission;
