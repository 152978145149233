import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import {
  formatNumber,
  IError,
  Paper,
  roundDecimal,
  Skeleton,
} from '@funfarm/kit';

import { EPeriod, ISelectRanks, ISelectSummary } from 'types';
import { getSelectRanks, getSelectSummary } from 'actions';

import { Helper } from 'components/Helper';

interface IProps {
  period: keyof typeof EPeriod;
}

export const Indicators = (props: IProps) => {
  const { period } = props;
  const { t } = useTranslation();

  const { isLoading, data } = useQuery<ISelectSummary, IError>({
    queryKey: ['select', 'summary', period],
    queryFn: () => getSelectSummary(period),
  });

  const { data: rankData } = useQuery<ISelectRanks, IError>({
    queryKey: ['select', 'ranks', period],
    queryFn: () => getSelectRanks(period),
  });

  return (
    <>
      <Paper className="indicator">
        <h4 className="header">
          {t('Select rating')}
          <Helper path="selectRating" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{roundDecimal(data?.score)}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : period === 'current_month' ? (
          <div className="caption">
            {t('Rang place X from Y', {
              X: roundDecimal(rankData?.score_position),
              Y: roundDecimal(rankData?.rang_players_num),
            })}
          </div>
        ) : (
          <div className="caption">
            {t('Avg at rang')}: {roundDecimal(data?.score_rank)}
          </div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('Distance')}
          <Helper path="distance" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{roundDecimal(data?.distance)}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : period === 'current_month' ? (
          <div className="caption">
            {t('Rang place X from Y', {
              X: roundDecimal(rankData?.distance_position),
              Y: roundDecimal(rankData?.rang_players_num),
            })}
          </div>
        ) : (
          <div className="caption">
            {t('Recommended')}: {data?.distance_recommended}
          </div>
        )}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">
          {t('Sessions count per month')}
          <Helper path="monthSession" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{roundDecimal(data?.sessions_per_month)}</div>
        )}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">
          {t('MTT Session')}
          <Helper path="mttSession" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{roundDecimal(data?.mtt)}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : period === 'current_month' ? (
          <div className="caption">
            {t('Rang place X from Y', {
              X: roundDecimal(rankData?.mtt_per_session_position),
              Y: roundDecimal(rankData?.rang_players_num),
            })}
          </div>
        ) : (
          <div className="caption">
            {t('Recommended')}: {roundDecimal(data?.mtt_recommended)}
          </div>
        )}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">{t('ABI')}</h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{roundDecimal(data?.abi)}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : period === 'current_month' ? (
          <div className="caption">
            {t('Rang place X from Y', {
              X: roundDecimal(rankData?.abi_position),
              Y: roundDecimal(rankData?.rang_players_num),
            })}
          </div>
        ) : (
          <div className="caption">
            {t('Rang ABI')}: {roundDecimal(data?.abi_rank)}
          </div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('AFS')}
          <Helper path="afs" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{roundDecimal(data?.afs)}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption">
            {roundDecimal(data?.afs_growth || 0)}{' '}
            {t('last', { count: Math.round(data?.afs_growth ?? 0) })}{' '}
            {t('month', { count: Math.round(data?.afs_growth ?? 0) })}
          </div>
        )}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">{t('Country')}</h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{data?.country}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption"></div>
        )}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('Reentry')}
          <Helper path="reentry" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{`${formatNumber(data?.reentry)}%`}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : (
          <div className="caption">
            {t('Recommended')}: {data?.reentry_recommended}
          </div>
        )}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">
          {t('Early-Early/Mid')}
          <Helper path="earlyEarlyMid" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{`${formatNumber(data?.early_early_mid_pct)}%`}</div>
        )}
        {/*{*/}
        {/*    isLoading ?*/}
        {/*        <Skeleton size="xsmall" /> :*/}
        {/*        <div className="caption">{t('Recommended')}: {data?.early_early_mid_recommended}</div>*/}
        {/*}*/}
      </Paper>

      <Paper className="indicator">
        <h4 className="header">
          {t('Active rooms', { context: 'indicator' })}
          <Helper path="activeRooms" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{formatNumber(data?.active_rooms_count)}</div>
        )}
        {isLoading ? (
          <Skeleton size="xsmall" />
        ) : (
          period === 'current_month' && (
            <div className="caption">
              {t('Rang place X from Y', {
                X: roundDecimal(rankData?.active_rooms_count_position),
                Y: roundDecimal(rankData?.rang_players_num),
              })}
            </div>
          )
        )}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">
          {t('Regspeed PKO')}
          <Helper path="Regspeed" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{formatNumber(data?.type_1, '%')}</div>
        )}
        {/*{*/}
        {/*    isLoading ?*/}
        {/*        <Skeleton size="xsmall" /> :*/}
        {/*        <div className="caption">{t('Recommended')}: {data?.type_1_recommended}</div>*/}
        {/*}*/}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">{t('Fullring Regspeed Freezout')}</h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{formatNumber(data?.type_2, '%')}</div>
        )}
        {/*{*/}
        {/*    isLoading ?*/}
        {/*        <Skeleton size="xsmall" /> :*/}
        {/*        <div className="caption">{t('Recommended')}: {data?.type_2_recommended}</div>*/}
        {/*}*/}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">{t('Shorthanded Regspeed Freezout')}</h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{formatNumber(data?.type_3, '%')}</div>
        )}
        {/*
          isLoading ?
            <Skeleton size="xsmall" /> :
            <div className="caption">{t("Recommended")}: {data?.type_3_recommended}</div>
        */}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">{t('Shorthanded turbo & hyper PKO')}</h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{formatNumber(data?.type_4_5, '%')}</div>
        )}
        {/*
          isLoading ?
            <Skeleton size="xsmall" /> :
            <div className="caption">{t("Recommended")}: {data?.type_4_5_recommended}</div>
        */}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">
          {t('Fullring turbo PKO')}
          <Helper path="Fullring" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{formatNumber(data?.type_6, '%')}</div>
        )}
        {/*{*/}
        {/*    isLoading ?*/}
        {/*        <Skeleton size="xsmall" /> :*/}
        {/*        <div className="caption">{t('Recommended')}: {data?.type_6_recommended}</div>*/}
        {/*}*/}
      </Paper>
      <Paper className="indicator">
        <h4 className="header">
          {t('Bad turbo & hyper tourney')}
          <Helper path="Badturbo" />
        </h4>
        {isLoading ? (
          <Skeleton size="xxxlarge" />
        ) : (
          <div className="value">{formatNumber(data?.type_7_8_9, '%')}</div>
        )}
        {/*{*/}
        {/*    isLoading ?*/}
        {/*        <Skeleton size="xsmall" /> :*/}
        {/*        <div className="caption">{t('Recommended')}: {data?.type_7_8_9_recommended}</div>*/}
        {/*}*/}
      </Paper>
    </>
  );
};
