import { subMonths } from 'date-fns';

import { toApiDateString } from 'helpers/formatDates';
import { FilterWithPeriod } from 'types/table';

const oldDate = subMonths(new Date(), 2);

export const preparePeriod = (filter: FilterWithPeriod) => ({
  ...filter,
  date_start: toApiDateString(filter.date_start || oldDate),
  date_end: toApiDateString(filter.date_end || new Date()),
});
