import React, { useCallback, useContext, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Dialog,
  IconEdit,
  IconPlus,
  IError,
  Input,
  MultiSelect,
  Tabs,
} from '@funfarm/kit';

import { useOptions } from 'helpers/useOptions';
import { IFilterTemplate } from 'types';
import {
  deleteFilters,
  getRooms,
  getTourneyTypes,
  postFilters,
  putFilters,
} from 'actions';

import { TourContext } from 'components/Tournaments/TourProvider';

import css from './templates.module.scss';

interface IProps {
  template?: IFilterTemplate;
}

export const EditTemplate = (props: IProps) => {
  const { template } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLDivElement>(null);
  const { setFilterParams } = useContext(TourContext);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [dialog, setDialog] = useState(false);

  const { data: roomsData } = useQuery<any, IError>({
    queryKey: ['rooms'],
    queryFn: () => getRooms(),
  });
  const roomsOptions = useOptions(roomsData, 'id', 'title');

  const { data: tourneyTypesData, isLoading: isTourneyTypesLoading } = useQuery<
    any,
    IError
  >({
    queryKey: ['tourney-types'],
    queryFn: () => getTourneyTypes(),
  });
  const tourneyTypesOptions = useOptions(tourneyTypesData, 'value', 'label');

  const defaultValues: IFilterTemplate = template ?? {
    name: '',
    bi_start: '',
    rooms_id: '',
    tourney_type: '',
  };

  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onSubmit = (formData: IFilterTemplate) => {
    setSubmitted(true);

    return (template ? putFilters(formData) : postFilters(formData))
      .then(() => {
        setDialog(false);

        queryClient.invalidateQueries({ queryKey: ['filters'] });
      })
      .catch((error) => {
        console.error(error);

        return false;
      })
      .finally(() => setSubmitted(false));
  };

  const handleDelete = () => {
    setSubmitted(true);

    if (!template) return;

    return deleteFilters(template)
      .then(() => {
        setDialog(false);

        queryClient.invalidateQueries({ queryKey: ['filters'] });
      })
      .catch((error) => {
        console.error(error);

        return false;
      })
      .finally(() => setSubmitted(false));
  };

  const handleClear = () => {
    reset(defaultValues);
    setDialog(false);
  };

  const setTemplate = useCallback(() => {
    const now = new Date().getTime();
    setFilterParams!({
      ...template?.value,
      date_start: now,
      date_end: now + 12 * 3600 * 1000,
    });
  }, [setFilterParams, template?.value]);

  return (
    <>
      {!template ? (
        <Tabs.Header
          index="edit-template"
          ref={buttonRef}
          className={css.tabHeader}
          style={{ maxWidth: 'none' }}
        >
          <IconPlus />
          <div onClick={() => setDialog(true)}>{t('Create template')}</div>
        </Tabs.Header>
      ) : (
        <Tabs.Header
          index={template.name}
          ref={buttonRef}
          className={css.tabHeader}
        >
          <div onClick={setTemplate} className={css.name}>
            {template.name}
          </div>
          <div onClick={() => setDialog(true)} className={css.editIcon}>
            <IconEdit />
          </div>
        </Tabs.Header>
      )}
      {dialog && (
        <Dialog
          // className={css.popup}
          open={dialog}
          header={t(template ? 'Edit template' : 'Create template')}
          onClose={() => setDialog(false)}
          wide={true}
          // positionRef={buttonRef}
          backdrop={false}
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Controller
              control={control}
              name="name"
              rules={{
                required: 'Template name is required',
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('Template name')}
                  required
                  error={!!formState.errors.name}
                  description="Укажите значения, которые будут применяться при выборе шаблона"
                />
              )}
            />
            <Controller
              control={control}
              name="rooms_id"
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  label={t('Rooms')}
                  options={roomsOptions}
                  maxBadges={3}
                />
              )}
            />
            <div style={{ width: 'fit-content' }}>
              <Controller
                control={control}
                name="bi_start"
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('Min Buy-in')}
                    labelPosition="left"
                  />
                )}
              />
            </div>
            {/*<Controller*/}
            {/*    control={control}*/}
            {/*    name="date_start"*/}
            {/*    render={({ field }) => (*/}
            {/*        <InputDate*/}
            {/*            {...field}*/}
            {/*            showTimeInput*/}
            {/*            label={t('Start date')}*/}
            {/*            timeInputLabel={t('Time')}*/}
            {/*            dateFormat = 'dd.MM.yyyy HH:mm'*/}
            {/*        />*/}
            {/*    )} />*/}
            {/*<Controller*/}
            {/*    control={control}*/}
            {/*    name="date_end"*/}
            {/*    render={({ field }) => (*/}
            {/*        <InputDate*/}
            {/*            {...field}*/}
            {/*            showTimeInput*/}
            {/*            label={t('Start date')}*/}
            {/*            timeInputLabel={t('Time')}*/}
            {/*            dateFormat = 'dd.MM.yyyy HH:mm'*/}
            {/*        />*/}
            {/*    )} />*/}
            <Controller
              control={control}
              name="tourney_type"
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  maxBadges={3}
                  options={tourneyTypesOptions.map((item) => ({
                    ...item,
                    label: t('Tourney ' + item.label),
                  }))}
                  loading={isTourneyTypesLoading}
                  labelPosition="inside"
                  label={t('Tourney type')}
                  checkboxes
                />
              )}
            />

            <div className={css.actions}>
              {template && (
                <Button
                  className={css.buttonDelete}
                  color="red"
                  view="light"
                  disabled={submitted}
                  onClick={handleDelete}
                >
                  {t('Delete')}
                </Button>
              )}
              <Button onClick={handleClear} disabled={submitted}>
                {t('Cancel')}
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                type="submit"
                color="secondary"
                loading={submitted}
                disabled={!formState.isValid}
              >
                {t(template ? 'Save template' : 'Create template')}
              </Button>
            </div>
          </form>
        </Dialog>
      )}
    </>
  );
};
