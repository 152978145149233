import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconPalette = (props: IconProps) => {
  return (
    <Icon id="IconPalette" {...props}>
      <path
        d="M7 21.5C4.79086 21.5 3 19.7091 3 17.5V5.5C3 4.39543 3.89543 3.5 5 3.5H9C10.1046 3.5 11 4.39543 11 5.5V17.5C11 19.7091 9.20914 21.5 7 21.5ZM7 21.5H19C20.1046 21.5 21 20.6046 21 19.5V15.5C21 14.3954 20.1046 13.5 19 13.5H16.6569M11 7.84312L12.6569 6.18629C13.4379 5.40524 14.7042 5.40524 15.4853 6.18629L18.3137 9.01472C19.0948 9.79577 19.0948 11.0621 18.3137 11.8431L9.82843 20.3284M7 17.5H7.01"
        stroke="#ACAFB5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
