import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import {
  Button,
  IError,
  Skeleton,
  TableCell,
  TableRow,
  Toast,
} from '@funfarm/kit';

import { timestampToDate } from 'helpers/formatDates';
import { IPermissionsParams } from 'types/index';
import { deletePermissionsRequest, putPermissionsRequest } from 'actions/index';
import { getRooms } from 'actions/tournaments';

import css from 'components/Tournaments/TournamentsAll/table.module.scss';

import { TourContext } from '../TourProvider';

interface PermissionTableRowProps {
  permission: IPermissionsParams;
  refetch: () => void;
}

export const PermissionTableRow: React.FC<PermissionTableRowProps> = ({
  permission,
  refetch,
}) => {
  const { t } = useTranslation();
  const { openPlayedTournament, handleCurrentPlayedTournament } =
    useContext(TourContext);

  const { data: roomsData, isLoading: isRoomsLoading } = useQuery<any, IError>({
    queryKey: ['rooms'],
    queryFn: () => getRooms(),
  });

  const findRoomById = (id: number) => {
    return roomsData?.find((room: { id: number }) => room.id === id);
  };

  function getStatusLabel(statusCode: number) {
    switch (statusCode) {
      case 0:
        return 'Обрабатывается';
      case 1:
        return 'От себя';
      case 2:
        return 'Пакет 2';
      case 3:
        return '50% от себя';
      case 4:
        return 'Постоянно в пакет 4';
      case 5:
        return 'Запрещено';
      default:
        return 'Неизвестный статус';
    }
  }

  const onDontPlay = async () => {
    if (!permission?.id) {
      return;
    }

    const formData = {
      parameter: 'dont_play',
    };

    try {
      await putPermissionsRequest(permission.id, formData);
      Toast.success({ message: 'Данные обновлены!' });
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
      Toast.error({ message: 'Что-то пошло не так...' });
    }
  };

  const { mutate: deletePermission, status: deleteStatus } = useMutation({
    mutationFn: deletePermissionsRequest,
    onSuccess: () => {
      refetch();
      Toast.success({ message: 'Данные удалены!' });
    },
    onError: (error) => {
      console.error('Ошибка при удалении данных:', error);
      Toast.error({ message: 'Что-то пошло не так...' });
    },
  });

  const onDelete = async () => {
    if (!permission?.id) {
      return;
    }

    deletePermission(permission.id);
  };

  return (
    <TableRow className={classNames(css.tournamentsRow)}>
      {isRoomsLoading ? (
        <Skeleton />
      ) : (
        <TableCell id="room">{findRoomById(permission.roomid)?.name}</TableCell>
      )}
      <TableCell colspan={4} id="name" nowrap>
        {permission.name}
      </TableCell>
      <TableCell colspan={2} id="date_tourney">
        {timestampToDate(permission.date, 'yyyy.MM.dd HH:mm')}
      </TableCell>
      <TableCell colspan={2} id="bi">
        {permission.bi}
      </TableCell>
      <TableCell id="answer">
        <span
          className={classNames(
            css.permissionAnswer,
            permission.answer === 1 || permission.answer === 3
              ? css.permissionAnswerGreen
              : css.permissionAnswerRed,
          )}
        >
          {getStatusLabel(permission.answer)}
        </span>
      </TableCell>
      <TableCell colspan={3} id="action" align="right">
        {/* Conditional rendering for buttons based on `answer` */}
        {permission.answer === 1 || permission.answer === 3 ? (
          <>
            <Button
              color="secondary"
              size="small"
              className={classNames(css.button)}
              onClick={() => {
                openPlayedTournament();
                handleCurrentPlayedTournament(permission);
              }}
            >
              {t('Played')}
            </Button>
            <Button
              color="red"
              view="outlined"
              size="small"
              className={css.button}
              onClick={() => {
                onDontPlay();
              }}
            >
              {t("Don't played")}
            </Button>
          </>
        ) : permission.answer === 0 ? (
          <Button
            color="red"
            view="outlined"
            size="small"
            className={css.button}
            onClick={() => {
              onDelete();
            }}
            disabled={deleteStatus === 'pending'}
          >
            {t('Delete')}
          </Button>
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  );
};
