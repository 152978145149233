import React, { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Error, IError, Input, Row } from '@funfarm/kit';
import MoneyInput from '@funfarm/kit/Input/MoneyInput';
import WarningPaper from '@funfarm/kit/Paper/WarningPaper';

import { money } from 'helpers/numbers';
import { WithdrawalRequest } from 'types/finances';
import { createSendMoneyRequest } from 'actions/finances';

import css from 'components/Finances/finances.module.scss';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

type SendMoneyProps = {
  request: WithdrawalRequest;
  onClose: () => void;
  onSuccess?: () => void;
};

const SendMoney: FC<SendMoneyProps> = ({ request, onClose, onSuccess }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { getRoomDetails, refetch } = useBalancesAndBrm();
  const chosenRoom = useMemo(
    () => getRoomDetails(request.room.id),
    [getRoomDetails, request.room.id],
  );

  const isCrypto = useMemo(
    () => request.room.title.includes('Crypto'),
    [request.room.title],
  );

  return (
    <form
      onSubmit={handleSubmit((formData) => {
        if (!formData.amount) {
          setError('Пожалуйста, введите сумму');
        } else if (isCrypto && !formData.link) {
          setError('Пожалуйста, прикрепите ссылку на транзакцию');
        } else {
          setSubmitted(true);
          createSendMoneyRequest(
            request.id,
            formData.amount,
            formData.fee,
            formData.link,
            // screenshot_url?: string,
          )
            .then(() => {
              refetch();
              onClose();
              onSuccess && onSuccess();
            })
            .catch((error: IError) => {
              const typedError = error as { detail?: { message: string } };
              if (typedError.detail?.message) {
                setError(typedError.detail.message);
              }
            })
            .finally(() => setSubmitted(false));
        }
      })}
      noValidate
    >
      {error && <Error message={error} view="box" />}
      <p>
        Вы отправляете пользователю {request.user.name} на счёт{' '}
        {request.room.title}
      </p>

      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <MoneyInput
            {...field}
            label={
              chosenRoom?.balance_curr === undefined
                ? 'Сумма отправки'
                : `Сумма отправки (Баланс счёта ${money(chosenRoom?.balance_curr, request.currency.abrname)})`
            }
            placeholder="Введите сумму"
            currency={request.currency.symbol}
            onInput={() => setError(undefined)}
            error={!!errors.amount}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="fee"
        render={({ field }) => (
          <MoneyInput
            {...field}
            label="Комиссия"
            placeholder="Заполните, если есть комиссия"
            currency={request.currency.symbol}
            onInput={() => setError(undefined)}
            error={!!errors.fee}
          />
        )}
      />

      {isCrypto && (
        <>
          <WarningPaper>Обязательно вставьте ссылку на транзакцию</WarningPaper>
          <Controller
            control={control}
            name="link"
            render={({ field }) => (
              <Input
                {...field}
                label="Ссылка на транзакцию"
                placeholder="Вставьте ссылку на транзакцию"
                error={!!errors.link}
                required
              />
            )}
          />
        </>
      )}

      {/*<div className={css.dropzone}>*/}
      {/*    <UploaderArea label="Переместите скриншот или нажмите для выбора" />*/}
      {/*</div>*/}

      <Row className={css.formButtons}>
        <Button
          color="default"
          loading={submitted}
          label="Отмена"
          onClick={() => onClose()}
        />
        <Button
          color="secondary"
          type="submit"
          loading={submitted}
          disabled={!isValid}
          label="Отправить деньги"
        />
      </Row>
    </form>
  );
};

export default SendMoney;
