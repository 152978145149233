import { useTranslation } from 'react-i18next';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import {
  IconBookmark,
  IconChartLinePresentation,
  IconDiagramCircle,
  IconFinance,
  IconStar,
  IconUsers,
} from '@funfarm/kit';
import IconDocumentText from '@funfarm/kit/Icon/icons/DocumentText';

import { preparePath } from 'helpers';
import { IRoute } from 'types';

import FeatureRequest from 'components/FeatureRequests/FeatureRequest';
import FinanceBalance from 'components/Finances/Balance/FinanceBalance';
import WithdrawalRequests from 'components/Finances/Withdrawals/WithdrawalRequests';
import { Info } from 'components/Info';
import { ErrorLayout, Layout, NavigationLayout } from 'components/Layout';
import { Player } from 'components/Player';
import { Profile } from 'components/Profile';
import { Select } from 'components/Select';
import { Statistic } from 'components/Statistic';
import { Tournaments } from 'components/Tournaments';
import { TournamentsAllNew } from 'components/Tournaments/TournamentsAll/TournamentsAllNew';
import { TournamentsLimits } from 'components/Tournaments/TournamentsLimits/TournamentsLimits';
import { TournamentsPermissions } from 'components/Tournaments/TournamentsPermissions/TournamentsPermissions';
import { TrainerDashboard } from 'components/Trainings/Dashboard';
import { TrainingReports } from 'components/Trainings/Reports';

import { EducationModule } from './Education/EducationModule/EducationModule';
import { EducationModuleTask } from './Education/EducationModuleTask';
import { EducationWayPage } from './Education/EducationWayPage/EducationWayPage';
import { Education } from './Education';

export const useRoutes = () => {
  const { t } = useTranslation();

  const routes: IRoute[] = [
    {
      path: '/',
      Component: Layout,
      ErrorBoundary: ErrorLayout,
      handle: {
        visible: false,
        layout: <NavigationLayout />,
      },
      children: [
        {
          index: true,
          element: <Navigate to={'info'} replace />,
          handle: {
            visible: false,
          },
        },
        {
          path: 'auth',
          Component: () => <>Auth</>,
          handle: {
            layout: (
              <>
                Авторизация успешна.
                <br />
                Вернитесь на предыдущую страницу.
              </>
            ),
            visible: false,
          },
        },
        {
          path: 'profile',
          Component: Profile,
          handle: {
            name: t('Profile'),
            icon: <IconUsers />,
            crumb: false,
            visible: false,
          },
        },
        {
          path: 'info',
          Component: Info,
          handle: {
            name: t('Info'),
            icon: <IconDocumentText />,
            crumb: false,
          },
        },
        {
          path: 'player',
          Component: Player,
          handle: {
            name: t('Rang changes'),
            icon: <IconChartLinePresentation />,
            crumb: false,
          },
        },
        {
          path: 'statistic',
          Component: Statistic,
          handle: {
            name: t('Statistic'),
            icon: <IconDiagramCircle />,
            crumb: false,
          },
        },
        {
          path: 'select',
          Component: Select,
          handle: {
            name: t('Select'),
            icon: <IconBookmark />,
            crumb: false,
          },
        },
        {
          path: 'tournaments',
          Component: Tournaments,
          handle: {
            name: t('Tournaments'),
            icon: <IconStar />,
            crumb: false,
          },
          children: [
            {
              index: true,
              element: <TournamentsAllNew />,
              handle: {
                visible: false,
              },
            },
            {
              index: false,
              path: 'new',
              element: <TournamentsAllNew />,
              handle: {
                visible: false,
              },
            },
            {
              path: 'permissions',
              element: <TournamentsPermissions />,
              handle: {
                visible: false,
              },
            },
            {
              path: 'limits',
              element: <TournamentsLimits />,
              handle: {
                visible: false,
              },
            },
          ],
        },
        {
          path: 'finance-balance',
          Component: FinanceBalance,
          handle: {
            name: t('Finances'),
            icon: <IconFinance />,
            crumb: false,
          },
        },
        {
          path: 'finance-requests',
          Component: WithdrawalRequests,
          handle: {
            visible: false,
          },
        },
        {
          path: 'trainings',
          Component: TrainingReports,
          handle: {
            visible: false,
          },
        },
        {
          path: 'trainings/dashboard',
          Component: TrainerDashboard,
          handle: {
            visible: false,
          },
        },
/*
        {
          path: 'education',
          Component: Education,
          handle: {
            name: t('Finances'),
            icon: <IconFinance />,
            crumb: false,
          },
          children: [
            {
              index: true,
              element: <EducationWayPage />,
              handle: {
                visible: false,
              },
            },
            {
              path: ':id',
              element: <EducationModule />,
              handle: {
                visible: false,
              },
            },

            {
              path: ':moduleId/tasks/:taskId',
              element: <EducationModuleTask />,
              handle: {
                visible: false,
              },
            },

            // {
            //   path: 'my-profile',
            //   element: <EducationProfile />,
            //   handle: {
            //     visible: false,
            //   },
            // },
          ],
        },*/
      ],
    },
    {
      path: 'feature-requests',
      Component: FeatureRequest,
      handle: {
        visible: false,
      },
    },
  ];

  return preparePath(routes);
};

export const Routes = () => {
  const routes: IRoute[] = useRoutes();

  return (
    <RouterProvider
      router={createBrowserRouter([
        // ...getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI]).map((r) => r.props),
        ...routes,
      ])}
    />
  );
};
