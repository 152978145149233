import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Checkbox, Paper, Row } from '@funfarm/kit';

import { FinanceRoom, WithdrawalRequestsFilter } from 'types/finances';
import { FilterComponent } from 'types/table';

import css from 'components/Finances/finances.module.scss';
import AccountSelect from 'components/Select/AccountSelect';

const WithdrawalsFilter: FilterComponent<WithdrawalRequestsFilter> = ({
  values,
  setValues,
}) => {
  const { control, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const currentValues = watch();

  useEffect(() => {
    setValues({
      ...values,
      rooms: currentValues.account,
    });
  }, [currentValues, setValues, values]);

  return (
    <Paper className={css.transfersFilter}>
      <Row gap={2}>
        <AccountSelect
          control={control}
          label="Счёт"
          labelPosition="inside"
          filterFunction={(room: FinanceRoom) =>
            Number(room.balance) > 0 && !!room.allowtransfer
          }
          required={false}
          multiChoice
        />

        <Controller
          control={control}
          name="countries"
          render={({ field }) => (
            <Checkbox
              {...field}
              label="Россия и Беларусь"
              labelPosition="right"
              type="radio"
              name="countries"
              value="ru"
              checked={values?.region_type === 'ru'}
              onChange={(e) =>
                setValues({
                  ...values,
                  region_type: e.target.checked ? 'ru' : undefined,
                })
              }
            />
          )}
        />

        <Controller
          control={control}
          name="countries"
          render={({ field }) => (
            <Checkbox
              {...field}
              label="Остальные страны"
              labelPosition="right"
              type="radio"
              name="countries"
              value="other"
              checked={values?.region_type === 'other'}
              onChange={(e) =>
                setValues({
                  ...values,
                  region_type: e.target.checked ? 'other' : undefined,
                })
              }
            />
          )}
        />
      </Row>
    </Paper>
  );
};

export default WithdrawalsFilter;
